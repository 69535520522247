import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BiSearch, BiTimeFive } from "react-icons/bi";
import { BsToggleOff, BsToggleOn, BsPencilSquare } from "react-icons/bs";
import { AiOutlinePlus } from 'react-icons/ai';
import { SiSquare } from "react-icons/si";
import { BottomSheet } from 'react-spring-bottom-sheet';
import {
    getVendorItems,
    getVendorSearchItems,
    vendorToggleItem,
    getAllItemCategories,
    vendorfilterCategory,
} from "../../../../redux/vendor/items/action";
import { Link } from "react-router-dom";
import image from '../../../../assets/images/nodata.png'
import VendorFooter from "../../VendorFooter";
import { IoIosTime } from 'react-icons/io';
import { WEBSITE_URL } from '../../../../api';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';

export class InventoryItems extends Component {
    state = {
        item: false,
        items: {},
        category_id: 'ALL',
        category_name: 'ALL',
        itemcategories: {},
        searchinput: '',
        showScheduleModal: false,
        selectedItem: null,
        selectedDateTime: '',
        nextBusinessDay: null,
        nextBusinessSchedule: null,
        scheduleDataExists: false,
        customDateSelected: false,
        originalItems: {}
    };
    componentDidMount() {
        const { user } = this.props;
        this.props.getVendorItems(user.data.auth_token, user.data.store[0].id)
            .then((response) => {
                this.setState({
                    items: response.payload.items,
                    originalItems: response.payload.items
                });
                this.props.getAllItemCategories(user.data.auth_token, user.data.store[0].id)
                    .then((response) => {
                        this.setState({ itemcategories: response.payload.data });
                    })
            })
    }
    filterCategory = (id, name) => {
        this.setState({ category_id: id }, () => {
            this.props.getVendorSearchItems(this.state.searchinput, this.state.category_id)
                .then((response) => {
                    this.setState({ items: [...response.data.data] });
                });
        })
        this.setState({ category_name: name })
        this.props.vendorfilterCategory(id)
            .then((response) => {
                this.setState({ items: response.data.items });
            }, this.closeItemMenu());
    };
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    getNextBusinessDay = () => {
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const today = new Date();
        let nextDayIndex = today.getDay() + 1; // Get the index of the next day

        if (nextDayIndex === daysOfWeek.length) {
            nextDayIndex = 0; // If today is Saturday, the next day is Sunday
        }

        return daysOfWeek[nextDayIndex];
    }


    toggleItem = (item) => {
        const { items } = this.state
        const { setUpdatedItemCategory } = this.props;
        const selected = items.find((data, index) => data.id === item.id)

        if (item.is_active && item.is_schedule == 1) {
            const scheduleData = item.schedule_data ? JSON.parse(item.schedule_data) : {};

            const nextBusinessDay = this.getNextBusinessDay(); // Calculate next business day dynamically


            const nextBusinessSchedule = scheduleData[nextBusinessDay] || null;

            if (nextBusinessSchedule && nextBusinessSchedule?.length > 0) {
                const openingTime = nextBusinessSchedule[0].open;

                // Combine the date of the next business day with the opening time in Asia/Kolkata time zone
                const nextBusinessDate = moment.tz('Asia/Kolkata');
                while (nextBusinessDate.format('dddd').toLowerCase() !== nextBusinessDay) {
                    nextBusinessDate.add(1, 'day');
                }
                const [hours, minutes] = openingTime.split(':').map(Number);
                nextBusinessDate.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });

                // Format the date to ISO 8601 in the local time zone
                const selectedDateTime = nextBusinessDate.format(); // This keeps it in the local time zone

                this.setState({
                    showScheduleModal: true,
                    selectedItem: item,
                    nextBusinessDay,
                    nextBusinessSchedule,
                    scheduleDataExists: Boolean(nextBusinessSchedule),
                    selectedDateTime
                });
            } else {
                this.setState({
                    showScheduleModal: true,
                    selectedItem: item,
                    nextBusinessDay,
                    nextBusinessSchedule,
                    scheduleDataExists: false,
                    selectedDateTime: null
                });
            }

        } else if (item.is_active && item.is_schedule == 0) {

            this.setState({
                showScheduleModal: true,
                selectedItem: item,
                scheduleDataExists: false,
                selectedDateTime: null
            });

        } else {
            selected.is_active = !selected.is_active;
            this.setState({ items: items });
            this.props.vendorToggleItem(item.id)
                .then((response) => {
                    if (response && response.payload) {
                        if (response.payload.data) {
                            this.setState({ items: [...response.payload.data] });
                        } else if (response.payload.items) {
                            this.setState({ items: [...response.payload.items] });
                        }
                    } else {
                        console.error("Invalid response structure", response);
                    }
                });
            setUpdatedItemCategory(item);
        }
    }
    closeItemMenu = () => {
        this.setState({ item: false })
    };
    openItemMenu = () => {
        this.setState({ item: true })
    }
    // search = (input) => {
    //     this.setState({ searchinput: input })
    //     this.props.getVendorSearchItems(input, this.state.category_id)
    //         .then((response) => {
    //             this.setState({ items: [...response.data.data] });
    //         });
    // };

    search = (input) => {
        this.setState({ searchinput: input });

        if (input.trim() === "") {
            // If the search input is empty, reset to the original state (assuming you have an originalItems array)
            this.setState({ items: [...this.state.originalItems] });
        } else {
            // Filter the items based on the input
            const filteredItems = this.state.originalItems.filter(item =>
                item.name.toLowerCase().includes(input.toLowerCase())
            );

            this.setState({ items: filteredItems });
        }
    };


    downloadItems = (store_id) => {
        window.location = WEBSITE_URL + "/export-store-items/" + this.props.user.data.auth_token + "/" + store_id;

    }
    handleOptionChange = (event) => {
        const { value, name } = event.target;

        if (name === 'open_time') {
            if (value === 'custom') {
                this.setState({
                    customDateSelected: true,
                    permanentlyClose: false,
                    selectedDateTime: '', // Reset selected date-time if switching to custom
                });
            } else if (value === 'next-business-day') {
                this.setState({
                    customDateSelected: false,
                    permanentlyClose: false,
                    selectedDateTime: '', // Reset selected date-time for next-business-day
                });
            }
        } else if (name === 'permenantClose') {
            this.setState({
                customDateSelected: false,
                permanentlyClose: true,
                selectedDateTime: '12/12/2050', // Set to permanent close date
            });
        }
    };


    handleScheduleSubmit = () => {


        const { selectedItem, selectedDateTime,permanentlyClose} = this.state;
        const { user } = this.props;




        axios.post(WEBSITE_URL + "/add-item-reopen-time", {
            date: selectedDateTime,
            category: selectedItem?.id,
        }).then(response => {
            this.props.getVendorItems(user.data.auth_token, user.data.store[0].id)
                .then((response) => {
                    this.setState({
                        items: response.payload.items,
                        originalItems: [...response.payload.items] // Save the original list

                    });
                    // this.props.getAllItemCategories(user.data.auth_token, user.data.store[0].id)
                    //     .then((response) => {
                    //         this.setState({ itemcategories: response.payload.data });
                    //     })
                })
        });

        this.setState({ showScheduleModal: false, selectedItem: null, selectedDateTime: '', customDateSelected: false });
    }

    render() {
        const { itemcategories, items, showScheduleModal, selectedItem, selectedDateTime,
            nextBusinessDay, nextBusinessSchedule, scheduleDataExists, customDateSelected, permanentlyClose } = this.state;



        return (
            <React.Fragment>

                {/* Schedule Modal */}
                <Modal show={showScheduleModal} onHide={() => this.setState({ showScheduleModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule Item Reopening</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {scheduleDataExists ? (
                            <div>
                                <form>
                                    <div className="form-group">
                                        <label>Reopen Time</label>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`next-business-day-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="next-business-day"
                                                checked={!this.state.customDateSelected && !this.state.permanentlyClose}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`next-business-day-radio${selectedItem?.id}`}>
                                                Next business day at {nextBusinessSchedule && nextBusinessSchedule[0].open}
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`choose-time-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="custom"
                                                checked={this.state.customDateSelected && !this.state.permanentlyClose}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`choose-time-radio${selectedItem?.id}`}>
                                                I will choose the time
                                            </label>
                                        </div>
                                        {customDateSelected && (
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                value={selectedDateTime}
                                                onChange={(e) => this.setState({ selectedDateTime: e.target.value })}
                                            />
                                        )}
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`close-perma-radio${selectedItem?.id}`}
                                                name="permenantClose"
                                                value="01/01/2050"
                                                checked={this.state.permanentlyClose}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`choose-time-radio${selectedItem?.id}`}>
                                               I will open manually, Permentantly deactivate
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div>
                                <form>
                                    <div className="form-group">
                                        {/* <label>Reopen Time</label>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`next-business-day-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="next-business-day"
                                                checked={!customDateSelected}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`next-business-day-radio${selectedItem?.id}`}>
                                                Next business day at {nextBusinessSchedule && nextBusinessSchedule[0].open}
                                            </label>
                                        </div> */}
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`choose-time-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="custom"
                                                checked={customDateSelected}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`choose-time-radio${selectedItem?.id}`}>
                                                I will choose the time
                                            </label>
                                        </div>
                                        {customDateSelected && (
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                placeholder='Select a date & Time'
                                                value={selectedDateTime}
                                                onChange={(e) => this.setState({ selectedDateTime: e.target.value })}
                                            />
                                        )}
                                         <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`close-perma-radio${selectedItem?.id}`}
                                                name="permenantClose"
                                                value="01/01/2050"
                                                checked={permanentlyClose}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`choose-time-radio${selectedItem?.id}`}>
                                               I will open manually, Permentantly deactivate
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            // <div className="text-danger fw-bold fs-7">
                            //     Oops... Please turn on auto schedule and add scheduled opening and closing time for this item.
                            //     Then only item and items will be automatically turned on for the selected date and time.
                            //     <br />

                            //     <Link to={'/vendor-item-schedule/' + selectedItem?.id}>

                            //         <Button variant="primary" className='btn text-white inventory-browse-btn fs-6 mt-2' onClick={this.handleScheduleSubmit}>
                            //             Click to add scheduled opening and closing time for your item
                            //         </Button>
                            //     </Link>
                            // </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showScheduleModal: false })}>
                            Close
                        </Button>
                            <Button variant="primary" className='btn text-white inventory-browse-btn' onClick={this.handleScheduleSubmit}>
                                Save Changes
                            </Button>




                    </Modal.Footer>
                </Modal>
                <div className="d-flex flex-row align-items-center mt-3 searchBar py-2 px-2">
                    <input
                        type="text"
                        className="inventory-search w-100"
                        placeholder="Search for Items"
                        onChange={(event) => { this.search(event.target.value) }}
                    />
                    <span className="">
                        <BiSearch
                            set="curved"
                            style={{
                                fontSize: "2em",
                                color: "#FEA24B",
                            }}
                        />
                    </span>
                </div>
                <div className="inventory-add-on-count-head w-100 mt-3 px-2 py-3 d-flex justify-content-between align-items-center">
                    <div>
                        <div className="ms-3">
                            <span style={{ fontSize: '17px', fontWeight: '600' }}>{this.state.category_name}</span>
                        </div>
                        <div className="mt-1 ms-3">
                            <span>{items?.length} items</span>
                        </div>
                    </div>
                    <div className="py-2 px-2" onClick={() => this.downloadItems(items[0]?.store_id)}>
                        <button className="btn inventory-add-btn px-2">
                            Download Items
                        </button>
                    </div>
                    <Link to="/vendor-add-item">
                        <div className="py-2 px-2">
                            <button className="btn inventory-add-btn px-2">
                                <AiOutlinePlus /> ADD
                            </button>
                        </div>
                    </Link>
                </div>
                <div style={{ paddingBottom: '44vw' }}>
                    {items && items.length > 0 ? (
                        <div>
                            {items?.map((item, index) =>
                                <React.Fragment>
                                    <div className="inventory-product-list mt-3">
                                        <div className="inventory-product-list-items mt-4 ">
                                            <div className="inventory-item-icon d-flex align-items-start justify-content-between">
                                                <div className=" d-flex">
                                                    <div>
                                                        <SiSquare set="curved" style={{ fontSize: "9px", color: "#32BA7C", }} />
                                                    </div>
                                                    <div className="ms-2 pt-1">
                                                        <div className="inventory-item-name">
                                                            <span>
                                                                {item.name}
                                                            </span>
                                                        </div>
                                                        <div className="inventory-item-price mt-1">
                                                            <span>
                                                                {/* RS.{item.selling_price} */}
                                                                RS.{item.res_menu_price}
                                                            </span>
                                                        </div>
                                                        <div className="inventory-item-desc mt-1">
                                                            <span>
                                                                {item.description}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-around">
                                                    <div onClick={() => this.toggleItem(item)}>
                                                        {item.is_active ? (
                                                            <BsToggleOn set="curved" style={{ fontSize: "2em", color: "#59D69D", }} />
                                                        ) : (
                                                            <BsToggleOff set="curved" style={{ fontSize: "2em", }} />
                                                        )}
                                                    </div>
                                                    <div className='px-1'>
                                                        <Link to={"/vendor-edit-item/" + item.id}>
                                                            <BsPencilSquare className='pt-1' style={{ fontSize: "1.6em", color: "#fea24b", }} />
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/vendor-item-schedule/" + item.id}>
                                                            <BiTimeFive className='pt-1' style={{ fontSize: "1.8em", color: "#fea24b", }} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    ) : (
                        <div className="">
                            <div className='pt-3 d-flex flex-column align-items-center'>
                                <div className='mt-3 d-flex flex-column align-items-center'>
                                    <img src={image} alt="no items"
                                        style={{
                                            objectFit: 'cover',
                                            width: '70%'
                                        }} />
                                </div>
                                <span className='mt-4 fs-6' style={{ fontWeight: '700' }}>Sorry...No Items Found !!!</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-end inventory-btn pb-4 py-2 px-2" onClick={() => this.openItemMenu()}>
                    <button className="btn text-white inventory-browse-btn">
                        Browse Menu
                    </button>
                </div>
                <VendorFooter active_inventory={true} />
                <BottomSheet
                    open={this.state.item}
                    onDismiss={() => this.closeItemMenu()}
                    snapPoints={({ maxHeight }) => 0.5 * maxHeight}
                >
                    <div className='px-3 pb-5' >
                        <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                            {itemcategories?.length > 0 ? (
                                <>
                                    <div className="d-grid gap-2">
                                        <button className="offer-sheet-btn" onClick={() => this.filterCategory('ALL', 'ALL')}>
                                            All
                                        </button>
                                        {itemcategories?.map((category) =>
                                            <>
                                                <button className={this.state?.category_id === category.id
                                                    ? "offer-sheet-btn text-black border-dark"
                                                    : "offer-sheet-btn"} onClick={() => this.filterCategory(category.id, category.name)} >
                                                    {category.name}  {"( " + category.items.length + " Items )"}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="d-grid gap-2">
                                        <button className="offer-sheet-btn" onClick={() => this.closeItemMenu()}>
                                            No Categories
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </BottomSheet>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    items: state.items.items,
    itemcategories: state.items.itemcategories,
})

export default connect(mapStateToProps, {
    getVendorItems,
    getVendorSearchItems,
    vendorToggleItem,
    getAllItemCategories,
    vendorfilterCategory,
})(InventoryItems)
