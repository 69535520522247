import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { GiScooter } from "react-icons/gi";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../../api";
import Truncate from "react-truncate";


const MedicalStoreCard = ({ store, getStoreCouponText ,ratingCount}) => {
    return (
        <>
            <Link key={store.id} to={"/single-store/" + store.id}>
                <div className="px-3 mt-3 d-flex align-items-center">
                    <div className={`position-relative ${!store?.is_active && 'store-not-active'}`}>
                        <ProgressiveImage
                            delay={20}
                            src={
                                IMAGE_BASE_URL +
                                store?.image
                            }
                            placeholder={PLACE_HOLDER_IMAGE}
                        >
                            {(src, loading) => (
                                <img
                                    src={src}
                                    style={{
                                        width: "30vw",
                                        height: "33vw",
                                        borderRadius:
                                            "20px",
                                        objectFit: "cover",
                                        backgroundRepeat:
                                            "round",
                                    }}
                                    className=""
                                    alt="grosav"
                                />
                            )}
                        </ProgressiveImage>
                        <div
                            className="position-absolute"
                            style={{
                                top: 0,
                                width: "30vw",
                                height: "33vw",
                                borderRadius: "20px",
                            }}
                        ></div>
                        {store.is_buy_text ? (
                            <div className="ribbon-buy">
                                <span>
                                    {store.is_buy_text}
                                </span>
                            </div>
                        ) : null}
                        {(store?.offer_promotion_text ||
                            store?.coupons?.length > 0) && (
                                <>
                                   {getStoreCouponText(store)}
                                </>
                            )}
                    </div>
                    <div className={`flex-grow-1 ms-3 pe-5 mt-1`}>
                        <h5
                            className="fs-6 m-0"
                            style={{
                                color: "#09051C",
                                fontWeight: "600",
                            }}
                        >
                            {store.name}
                        </h5>
                        <div className={`d-flex align-items-center ${!store?.is_active && 'store-not-active'}`}>

                            {store?.ratings?.length > 0 ? <>
                                <span className="m all-resturant-rating">
                                    {this.ratingCount(store?.ratings, store?.ratings?.length)}
                                </span>{" "}
                                <AiFillStar className="me-2"
                                    style={{ color: "FF9A62" }}
                                    size={11}
                                />
                            </>
                                : <>
                                    <span className="m all-resturant-rating">
                                        {store?.rating}
                                    </span>{" "}
                                    <AiFillStar className="me-2"
                                        style={{ color: "FF9A62" }}
                                        size={11}
                                    />
                                </>}
                            <span
                                className=" me-2"
                                style={{
                                    fontSize: "13px",
                                    fontWeight: "650",
                                    color: "#3B3B3B",
                                }}
                            >
                                {store?.preparation_time
                                    ? store.preparation_time +
                                    " Min - "
                                    : ""}{" "}
                                {store?.delivery_radius
                                    ? store.delivery_radius +
                                    " Km "
                                    : ""}
                            </span>
                        </div>
                        <div
                            className="resturant-description"
                            style={{
                                color: "#b5b5b5",
                                fontSize: "14px",
                            }}
                        >
                            <Truncate lines={2}>
                                {store?.description}
                            </Truncate>
                        </div>
                        {store?.is_freedelivery === 1 && store?.is_active ? (
                            <div className="d-flex align-items-center resturant-description-top">
                                <GiScooter
                                    style={{
                                        color: "FF9A62",
                                    }}
                                    size={20}
                                />
                                <span className="all-resturant-delivery ms-2 mt-1">
                                    Free Delivery Upto...
                                </span>
                            </div>
                        ) : (
                            ""
                        )}

                        {!store?.is_active ? (
                            <div className="text-danger">
                                Store Closed
                            </div>
                        ) : (
                            null
                        )}
                    </div>
                </div>
            </Link>
        </>
    )
}

export default MedicalStoreCard
