import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import VendorBackButton from '../../Elements/VendorBackButton';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { BottomSheet } from 'react-spring-bottom-sheet'
import Loading from '../../Elements/loading'
import {
    getAllAddonCategories,
    addVendorAddon,
} from "../../../redux/vendor/items/action";
import Modal from "react-modal";
import image from '../../../assets/images/greentick.png'

export class VendorAddAddon extends Component {
    state = {
        loading: false,
        categorysheet: false,
        category: '',
        categoryname: '',
        vendoraddon: {},
        open: false,
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.getAllAddonCategories(user.data.auth_token, user.data.store[0].id)
        .then(()=>{
            setTimeout(() => {
                this.setState({ loading: false });
            }, 1500);
        })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { vendoraddon } = this.state;
        let formData = new FormData()
        formData.append("name", vendoraddon.title)
        formData.append("price", vendoraddon.price)
        formData.append("res_menu_price", vendoraddon.res_menu_price)
        formData.append("extra_by_grosav", vendoraddon.extra_by_grosav)
        formData.append("stock", vendoraddon.stock)
        formData.append("category", this.state.category)
        formData.append("token", user.data.auth_token)
        this.props.addVendorAddon(formData)
            .then((response) => {
                if (response) {
                    document.getElementById('addonform').reset()
                    this.setState({ categoryname: '' })
                    this.handleModal()
                }
            })
    }

    handleInput(e, name) {
        let addon = this.state.vendoraddon;
        addon[name] = e;
        this.setState({ vendoraddon: addon });
    }
    selectCategory = (category) => {
        const id = category.id;
        const name = category.name;
        this.setState({
            category: id,
            categoryname: name,
        })
        this.closeCategoryMenu();
    }
    closeCategoryMenu = () => {
        this.setState({ categorysheet: false })
    };
    openCategoryMenu = () => {
        this.setState({ categorysheet: true })
    }
    handleModal = () => {
        this.setState({ open: !this.state.open });
    };
    render() {
        const { addoncategories } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Add Addon" />
                        </div>
                        <div className="px-4 mt-4 pt-3">
                            <div className="mt-5 px-4 pt-4">
                                <div className="coupon-heading-one">
                                    <span>Add Addons to your Store</span>
                                </div>
                                <div className="coupon-heading-two text-muted">
                                    <span>Enter Addon Details</span>
                                </div>
                            </div>
                            <div className="form-coupon">
                                <Form onSubmit={this.onSubmit} id="addonform">
                                    <InputGroup className="mt-4 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            type="text"
                                            placeholder="Enter Addon title"
                                            className="input-coupon"
                                            required
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "title");
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            type="number" min={0}
                                            placeholder="Addon Price"
                                            className="input-coupon"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "price");
                                            }}
                                            required
                                        />
                                    </InputGroup> 

                                    <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            type="number" min={0}
                                            placeholder="Addon Restaurant Menu Price"
                                            className="input-coupon"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "res_menu_price");
                                            }}
                                            required
                                        />
                                    </InputGroup>

                                    <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            type="number" min={0}
                                            placeholder="Addon Extra Value"
                                            className="input-coupon"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "extra_by_grosav");
                                            }}
                                            required
                                        />
                                    </InputGroup>

                                    <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            type="number" min={0}
                                            placeholder="Stock"
                                            className="input-coupon"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "stock");
                                            }}
                                            required
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        className="mt-3 coupon-input p-2 d-flex justify-content-between"
                                        style={{ boxSizing: 'border-box' }}
                                        onClick={() => this.openCategoryMenu()}
                                    >
                                        <div>
                                            <input type="hidden" value={this.state.category} required />
                                            <label for='category-type' className='text-muted px-3 py-1'>
                                                {this.state.categoryname ? (
                                                    <>
                                                        {this.state.categoryname}
                                                    </>
                                                ) : (
                                                    <>
                                                        Select an Adddon Category
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                        <div>
                                            <span className='px-1 py-2 ml-3' id='category-type'  ><HiOutlineChevronRight style={{ color: '#DA6317' }} /></span>
                                        </div>
                                    </InputGroup>
                                    <BottomSheet
                                        open={this.state.categorysheet}
                                        onDismiss={() => this.closeCategoryMenu()}
                                        snapPoints={({ maxHeight }) => 0.36 * maxHeight}
                                    >
                                        <div className='px-3 pb-5' >
                                            <div className="mt-1 mb-2 " style={{
                                                fontWeight: '600',
                                                fontSize: '16px'
                                            }}>
                                                Select Addon Category
                                            </div>
                                            <div className='px-3 pb-5' >
                                                <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                                    {addoncategories?.addoncategories?.length > 0 ? (
                                                        <>
                                                            <div className="d-grid gap-2">
                                                                {addoncategories?.addoncategories?.map((category) =>
                                                                    <>
                                                                        <button className="offer-sheet-btn" onClick={() => this.selectCategory(category)} >
                                                                            {category.name}
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>
                                    <div className="position-fixed w-100 me-3 px-4" style={{ bottom: '0px', left: '0px' }}>
                                        <button type='submit' className='button-coupon p-3' >
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <Modal
                            isOpen={this.state.open}
                            ariaHideApp={false}
                            onRequestClose={() => this.handleModal()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div
                                className="container"
                                style={{
                                    borderRadius: "5px",
                                    height: "210px",
                                    zIndex: "9999",
                                }}
                            >
                                <React.Fragment>
                                <div className="mb-20 d-flex justify-content-center align-items-center">
                                        <img src={image} style={{ objectFit: 'contain', height: '110px' }} alt="success"/>
                                    </div>
                                    <div className="col-12 pb-3 d-flex flex-column align-items-center justify-content-center">
                                        <h1 className="pt-3 mb-0 font-weight-black h4">
                                            Addon
                                        </h1>
                                        <h1 className="pt-1 mb-0 font-weight-black h4">
                                            Added Successfully
                                        </h1>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Modal>
                    </React.Fragment>
                )}

            </React.Fragment>
        )
    }
}
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    addoncategories: state.items.addoncategories,
})
export default connect(mapStateToProps, { getAllAddonCategories, addVendorAddon })(VendorAddAddon)
