// import React, { Component } from "react";
// import { connect } from "react-redux";
// import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
// import ContentLoader from 'react-content-loader'
// import NormalOrders from "./NormalOrders";
// import AnyStoreOrders from "./AnyStoreOrders";
// import {     , cancelOrder, acceptOrder, cancelCourier } from "../../../redux/mobile/mobileOrder/action"
// import { emptyCheckout } from '../../../redux/mobile/checkOut/action'
// import { Link } from "react-router-dom";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import CourierOrders from "./CourierOrders";
// import { HiOutlineChevronLeft } from "react-icons/hi";

// export class index extends Component {
//     state = {
//         loading: false,
//         orders: [],
//         anyStore: [],
//         couriers: [],
//         type: this.props.match.params.type
//     }
//     componentDidMount() {
//         const { user } = this.props;
//         this.setState({ loading: true });

//         this.interval = setInterval(() => {
//             this.fetchOrders();
//         }, 10000); // 10000ms = 10 seconds
//         this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
//             if (response && response.payload && response.payload.success) {
//                 // this.setState({ loading: false});
//                 this.setState({
//                     loading: false,
//                     orders: response.payload.orders,
//                     anyStore: response.payload.anyStore,
//                     couriers: response.payload.couriers,
//                 });
//             }
//         })
//         this.props.emptyCheckout();
//     }

//     componentWillUnmount() {
//         clearInterval(this.interval);
//     }

//     fetchOrders = () => {
//         const { user } = this.props;
//         this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
//             if (response && response.payload && response.payload.success) {
//                 this.setState({
//                     loading: false,
//                     orders: response.payload.orders,
//                     anyStore: response.payload.anyStore,
//                     couriers: response.payload.couriers,
//                 });
//             }
//         });
//         this.props.emptyCheckout();
//     }
//     cancelOrder = (unique_order_id) => {
//         const { user } = this.props;
//         this.setState({ loading: true, open: false, });
//         this.props.cancelOrder(user.data.auth_token, unique_order_id, this.state.cancel_reason, 'WALLET', "ANYSTORE").then((response) => {
//             if (response && response.payload && response.payload.success) {
//                 this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
//                     if (response && response.payload && response.payload.success) {
//                         // this.setState({ loading: false});
//                         this.setState({
//                             loading: false,
//                             orders: response.payload.orders,
//                             anyStore: response.payload.anyStore,
//                             couriers: response.payload.couriers,
//                         });
//                     }
//                 })
//             }
//         });
//     };

//     cancelCourier = (unique_courier_id) => {
//         const { user } = this.props;
//         this.setState({ loading: true, open: false, });
//         this.props.cancelCourier(user.data.auth_token, unique_courier_id, this.state.cancel_reason, "WALLET").then((response) => {
//             if (response && response.payload && response.payload.success) {
//                 this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
//                     if (response && response.payload && response.payload.success) {
//                         // this.setState({ loading: false});
//                         this.setState({
//                             loading: false,
//                             orders: response.payload.orders,
//                             anyStore: response.payload.anyStore,
//                             couriers: response.payload.couriers,
//                         });
//                     }
//                 })
//             }
//         });
//     };

//     acceptOrder = (unique_order_id) => {
//         const { user } = this.props;
//         this.setState({ loading: true, open: false, });
//         this.props.acceptOrder(user.data.auth_token, unique_order_id).then((response) => {
//             if (response && response.payload && response.payload.success) {
//                 this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
//                     if (response && response.payload && response.payload.success) {
//                         this.setState({
//                             loading: false,
//                             orders: response.payload.orders,
//                             anyStore: response.payload.anyStore,
//                             couriers: response.payload.couriers,
//                         });
//                     }
//                 })
//             }
//         });
//     };
//     render() {
//         return (
//             <React.Fragment>
//                 {this.state.loading ?
//                     (
//                         <React.Fragment>
//                             <ContentLoader
//                                 speed={1}
//                                 viewBox="0 0 280 576"
//                                 backgroundColor={'#F3F3F3'}
//                                 foregroundColor={'#FFFFFF'}>
//                                 <rect x="12" y="26" rx="8" ry="8" width="22" height="22" />
//                                 <rect x="12" y="85" rx="10" ry="10" width="200" height="30" />
//                                 <rect x="12" y="127" rx="10" ry="10" width="200" height="10" />
//                                 <rect x="18" y="178" rx="14" ry="10" width="249" height="159" />
//                                 <rect x="12" y="500" rx="10" ry="18" width="250" height="30" />
//                             </ContentLoader>
//                         </React.Fragment>
//                     ) : (
//                         <React.Fragment>
//                             <div className=""  >
//                                 <React.Fragment>
//                                     <div className='sticky-back-button d-flex flex-row justify-content-start align-items-center pt-2'>
//                                         <Link to="/home">
//                                             <div className='flex-shrink-1 text-center  mobile-back-button  position-relative m-3'
//                                                 onClick={() => this.onHandleBack()} >
//                                                 <span className="fw-bold fs-2 back-button-icon">
//                                                     <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
//                                                 </span>
//                                             </div>
//                                         </Link>

//                                     </div>
//                                 </React.Fragment>
//                             </div>
//                             <div className="px-3">
//                                 <div className="pt-2 mobile-runningorders"
//                                     style={{ fontWeight: "700", fontSize: "25px" }}>
//                                     Running Orders
//                                 </div>
//                                 <div className="" style={{ fontWeight: "400", fontSize: "12px", color: "black" }}>
//                                     Details about your running orders
//                                 </div>
//                             </div>
//                             <div className="mt-3">
//                                 <Tabs defaultActiveKey={this.state.type}
//                                     transition={true}
//                                     className="mb-3 text-center px-3 gap-2"
//                                     justify
//                                 >
//                                     <Tab eventKey="normal" title="Normal" >
//                                         <NormalOrders orders={this.state.orders} cancelOrder={(e) => this.cancelOrder(e)} />
//                                     </Tab>
//                                     <Tab eventKey="anystore" title="AnyStore">
//                                         <AnyStoreOrders anyStore={this.state.anyStore} acceptOrder={(e) => this.acceptOrder(e)} cancelOrder={(e) => this.cancelOrder(e)} history={this.props.history} />
//                                     </Tab>
//                                     <Tab eventKey="couriers" title="Couriers">
//                                         <CourierOrders couriers={this.state.couriers} acceptOrder={(e) => this.acceptOrder(e)} cancelCourier={(e) => this.cancelCourier(e)} />
//                                     </Tab>
//                                     {/* <Tab eventKey="contact" title="Courier Orders">
//                                     Contact
//                                 </Tab> */}
//                                 </Tabs>
//                             </div>
//                             <div className="position-fixed px-3 py-2 pb-3 w-100" style={{ bottom: "0px", backgroundColor: "#fff" }}>
//                                 <Link to={'/home'} >
//                                     <button className="btn w-100 py-3" style={{ color: "#FEFEFF", fontWeight: "700", fontSize: "14px", background: '#FEA24B', borderRadius: '15px' }} >
//                                         Back to home
//                                     </button>
//                                 </Link>
//                             </div>
//                         </React.Fragment>
//                     )
//                 }
//             </React.Fragment>
//         )
//     }
// }

// const mapStateToProps = (state) => ({
//     user: state.user.user,
// });

// export default connect(mapStateToProps, {
//     getAllRunningOrders,
//     cancelOrder,
//     emptyCheckout,
//     acceptOrder,
//     cancelCourier
// })(index);

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ContentLoader from "react-content-loader";
import NormalOrders from "./NormalOrders";
import AnyStoreOrders from "./AnyStoreOrders";
import CourierOrders from "./CourierOrders";
import { HiOutlineChevronLeft } from "react-icons/hi";
import {
    getAllRunningOrders,
    cancelOrder,
    acceptOrder,
    cancelCourier,
    emptyCheckout,
    getAllOrdersPagination,
} from "../../../redux/mobile/mobileOrder/action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OrdersPage = () => {
    const dispatch = useDispatch();
    //   const navigate = useNavigate();
    const navigate = useHistory();
    const { type } = useParams();

    const [currentPage, setCurrentPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const [anyStore, setAnyStore] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
    console.log("cuurentPage", currentPage);
    const scrollTimeout = useRef(null);

    const user = useSelector((state) => state.user.user);

    const fetchOrders = useCallback(async () => {
        setLoading(true);
        try {
            const response = await dispatch(
                getAllOrdersPagination(user.data.auth_token, currentPage)
            );
            console.log("response", response);

            if (response?.payload?.success) {
                setOrders((prev) => [...prev, ...response.payload.orders.data]);
                setAnyStore((prev) => [...prev, ...response.payload.anyStore.data]);
                setCouriers((prev) => [...prev, ...response.payload.couriers.data]);
            }
        } finally {
            setLoading(false);
            setIsFetchingNextPage(false);
        }
    }, [dispatch, user.data.auth_token, currentPage]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    const handleScroll = useCallback(() => {
        if (isFetchingNextPage || loading) return;

        const bottomReached =
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 50;

        if (bottomReached) {
            setIsFetchingNextPage(true);
            setCurrentPage((prev) => prev + 1);
        }
    }, [isFetchingNextPage, loading]);

    useEffect(() => {
        const debouncedScroll = () => {
            if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
            scrollTimeout.current = setTimeout(handleScroll, 500);
        };

        window.addEventListener("scroll", debouncedScroll);
        return () => {
            if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
            window.removeEventListener("scroll", debouncedScroll);
        };
    }, [handleScroll]);

    const handleCancelOrder = async (unique_order_id) => {
        setLoading(true);
        await dispatch(
            cancelOrder(
                user.data.auth_token,
                unique_order_id,
                "Reason",
                "WALLET",
                "ANYSTORE"
            )
        );
        setCurrentPage(1);
        setOrders([]);
        fetchOrders();
    };

    const handleAcceptOrder = async (unique_order_id) => {
        setLoading(true);
        await dispatch(acceptOrder(user.data.auth_token, unique_order_id));
        setCurrentPage(1);
        setOrders([]);
        fetchOrders();
    };

    const handleCancelCourier = async (unique_courier_id) => {
        setLoading(true);
        await dispatch(
            cancelCourier(
                user.data.auth_token,
                unique_courier_id,
                "Reason",
                "WALLET"
            )
        );
        setCurrentPage(1);
        setCouriers([]);
        fetchOrders();
    };

    if (loading && currentPage === 1) {
        return (
            <ContentLoader
                speed={1}
                viewBox="0 0 280 576"
                backgroundColor={"#F3F3F3"}
                foregroundColor={"#FFFFFF"}
            >
                <rect x="12" y="26" rx="8" ry="8" width="22" height="22" />
                <rect x="12" y="85" rx="10" ry="10" width="200" height="30" />
                <rect x="12" y="127" rx="10" ry="10" width="200" height="10" />
                <rect x="18" y="178" rx="14" ry="10" width="249" height="159" />
                <rect x="12" y="500" rx="10" ry="18" width="250" height="30" />
            </ContentLoader>
        );
    }

    return (
        <div>
            <div className="sticky-back-button d-flex flex-row justify-content-start align-items-center pt-2">
                <Link to="/home">
                    <div
                        className="flex-shrink-1 text-center mobile-back-button position-relative m-3"
                        onClick={() => navigate(-1)}
                    >
                        <span className="fw-bold fs-2 back-button-icon">
                            <HiOutlineChevronLeft
                                style={{ color: "#DA6317" }}
                            />
                        </span>
                    </div>
                </Link>
            </div>
            <div className="px-3">
                <div
                    className="pt-2 mobile-runningorders"
                    style={{ fontWeight: "700", fontSize: "25px" }}
                >
                    Running Orders
                </div>
                <div
                    className=""
                    style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "black",
                    }}
                >
                    Details about your running orders
                </div>
            </div>
            <div className="mt-3">
                <Tabs
                    defaultActiveKey={type}
                    transition={true}
                    className="mb-3 text-center px-3 gap-2"
                    justify
                >
                    <Tab eventKey="normal" title="Normal">
                        <NormalOrders
                            orders={orders}
                            cancelOrder={handleCancelOrder}
                        />
                    </Tab>
                    <Tab eventKey="anystore" title="AnyStore">
                        <AnyStoreOrders
                            anyStore={anyStore}
                            acceptOrder={handleAcceptOrder}
                            cancelOrder={handleCancelOrder}
                        />
                    </Tab>
                    <Tab eventKey="couriers" title="Couriers">
                        <CourierOrders
                            couriers={couriers}
                            acceptOrder={handleAcceptOrder}
                            cancelCourier={handleCancelCourier}
                        />
                    </Tab>
                </Tabs>
            </div>
            <div
                className="position-fixed px-3 py-2 pb-3 w-100"
                style={{ bottom: "0px", backgroundColor: "#fff" }}
            >
                <Link to={"/home"}>
                    <button
                        className="btn w-100 py-3"
                        style={{
                            color: "#FEFEFF",
                            fontWeight: "700",
                            fontSize: "14px",
                            background: "#FEA24B",
                            borderRadius: "15px",
                        }}
                    >
                        Back to home
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default OrdersPage;
