import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButtonWithCenterTitle from "../../../Elements/MobileBackButtonWithCenterTitle";
import {
    getAllUserAddress,
    updateDefaultAddress,
    getUserDetails,
    updateUserReduxInfo,
    deleteAddress
} from "../../../../redux/mobile/user/action";
import { GoLocation } from "react-icons/go";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Ink from "react-ink";
import Lottie from 'react-lottie'
import animationData from "../../../../assets/lottie/Loading-2.json";
import Loading from "../../../Elements/loading";
import { AiFillDelete } from "react-icons/ai";
import { IoCheckmarkCircle, IoHomeSharp } from "react-icons/io5";
import { MdWork } from "react-icons/md";
import { FaLocationArrow } from "react-icons/fa";
export class MyAddress extends Component {
    state = {
        modal: false,
        success_modal: false,
        id: '',
        addingLoad: false,
        change_modal: false,
    }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true })
        this.props.getAllUserAddress(user.data.auth_token, user.data.id).then((response) => {
            if (response.payload && response.payload.success) {
                this.setState({ loading: false })
            }
        });
    }
    handleDeafultAddress = (id) => {
        this.setState({ addingLoad: true })
        const { user } = this.props;
        ////// console.log(user.data.default_address_id);
        // if (user.data.default_address_id !== id) {
            this.props.updateDefaultAddress(id, user.data.auth_token).then((response) => {
                if (response.data && response.data.success) {
                    this.props.updateUserReduxInfo(user.data.auth_token).then((response) => {
                        if (response.payload && response.payload.success) {
                            this.props.getAllUserAddress(user.data.auth_token, user.data.id).then((response) => {
                                if (response.payload && response.payload.success) {
                                    this.setState({ addingLoad: false, change_modal: true })
                                    setTimeout(() => {
                                        this.setState({ change_modal: false })
                                    }, 5000);
                                }
                            });
                            const userSetAddress =
                                response?.payload &&
                                response?.payload?.data &&
                                response?.payload?.data?.default_address;
                            localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
                        }
                    });
                }
            });
        // }
    };
    deleteAddress = () => {
        this.setState({ loading: true, modal: false })
        const { user } = this.props;
        const { id } = this.state;
        this.props.deleteAddress(user.data.auth_token, id).then((response) => {
            if (response.payload && response.payload.success) {
                this.props.getAllUserAddress(user.data.auth_token, user.data.id).then((response) => {
                    if (response.payload && response.payload.success) {
                        this.setState({ loading: false, success_modal: true })
                        setTimeout(() => {
                            this.setState({ success_modal: false })
                        }, 5000);
                    }
                });
            }
        });
    }
    modalOpen = (e, id) => {
        e.preventDefault();
        this.setState({ modal: !this.state.modal, id: id })
    }
    successOpen = (e,) => {
        e.preventDefault();
        this.setState({ success_modal: !this.state.success_modal })

    }
    changeOpen = (e,) => {
        e.preventDefault();
        this.setState({ change_modal: !this.state.change_modal })

        let checkIsFromCart = localStorage.getItem("LocationFrom");

        if (checkIsFromCart == "CART") {
            localStorage.removeItem("LocationFrom");
            localStorage.removeItem("LocationData");
            this.props.history.push("/cart");

        }
    }
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        const { address, user } = this.props;
        
        const { id } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <Loading />
                    </React.Fragment>
                ) : (
                    this.state.addingLoad ? (
                        <div style={{ paddingTop: '65vw' }}>
                            <Lottie options={defaultOptions} height={100} width={100} />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div>
                                <MobileBackButtonWithCenterTitle
                                    title="My Address"
                                    history={this.props.history}
                                />
                            </div>
                            <div className=" px-4 mt-3 " style={{
                                paddingBottom: "15vh"
                            }}>
                                <div onClick={() => { this.handleDeafultAddress(address?.default_address?.id); }}
                                 className="w-100 mobile-address-card-selected px-3 py-2 mt-3"  >
                                    <div className="d-flex" >
                                        <div className="address-page-icons">
                                            {address?.default_address?.tag === "HOME" ? (
                                                <IoHomeSharp style={{ fontSize: '1.2em' }} />
                                            ) : address?.default_address?.tag === "WORK" ? (
                                                <MdWork style={{ fontSize: '1.2em' }} />
                                            ) : (
                                                <FaLocationArrow />
                                            )}
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <div className="d-flex justify-content-between align-items-center" >
                                                <div className="" style={{ fontSize: '1.2em', fontWeight: '750' }}>
                                                    {address?.default_address?.tag}
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1 mt-2 pe-5" >
                                                    {address?.default_address?.house ? address?.default_address?.house + ", " : ""}
                                                    {address?.default_address?.landmark ? address?.default_address?.landmark + ", " : ""}
                                                    {address?.default_address?.address}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {address?.data?.map((location) => (
                                    <div className="w-100 mobile-address-card px-3 py-2 mt-3"  >
                                        <div className="d-flex" >
                                            <div className="address-page-icons" onClick={() => { this.handleDeafultAddress(location.id); }}>
                                                {location?.tag === "HOME" ? (
                                                    <IoHomeSharp style={{ fontSize: '1.2em' }} />
                                                ) : location?.tag === "WORK" ? (
                                                    <MdWork style={{ fontSize: '1.2em' }} />
                                                ) : (
                                                    <FaLocationArrow />
                                                )}
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <div className="d-flex justify-content-between align-items-center" onClick={() => { this.handleDeafultAddress(location.id); }}>
                                                    <div className="" style={{ fontSize: '1.2em', fontWeight: '750' }}>
                                                        {location?.tag}
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-2">
                                                    <div className="flex-grow-1 pe-2"  onClick={() => { this.handleDeafultAddress(location.id); }}>
                                                        {location?.house ? location?.house + ", " : ""}
                                                        {location?.landmark ? location?.landmark + ", " : ""}
                                                        {location?.address}
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between ps-2">
                                                        <div className="address-delete p-2" onClick={(e) => { this.modalOpen(e, location.id) }} >
                                                            <AiFillDelete size={20} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                            <Link to={"/set-location"} className="mt-5 w-100 position-fixed px-3"
                                style={{ bottom: "11px", right: "0px", backgroundColor: "#fff", }}  >
                                <button onClick={() => this.openMenu()} className="btn w-100 py-3"
                                    style={{
                                        color: "#FEFEFF", fontWeight: "700", fontSize: "14px",
                                        background: "linear-gradient(98.81deg, #FEA24B -0.82%, #FD8814 101.53%)", borderRadius: "15px",
                                    }}  >
                                    Add New Address
                                </button>
                            </Link>
                            <Modal
                                isOpen={this.state.modal}
                                // onAfterOpen={afterOpenModal}
                                ariaHideApp={false}
                                onRequestClose={(e) => this.modalOpen(e)}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >
                                <div className='d-flex flex-column align-items-center' style={{ backgroundColor: '#fff', padding: '13px' }}>
                                    <div className="text-center">
                                        <span className='text-danger fs-4 fw-semibold'>Delete</span>
                                    </div>
                                    <div className='text-muted ms-4 mt-3'>
                                        <span>Are you sure you really want to delete ?</span>
                                    </div>

                                    <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                                        <div className="text-center mr-4 w-100">
                                            <button className="delete-button-meals p-2 px-2 w-100 position-relative"
                                                onClick={(e) => this.modalOpen(e)}
                                                style={{
                                                    borderColor: "#fd8814", color: "#fd8814", border: '1px solid #fd8814', width: "100%",
                                                }}>  NO
                                                <Ink duration={500} style={{ color: "#000" }} />
                                            </button>
                                        </div>

                                        <div className="text-center w-100">
                                            <button className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                                onClick={(e) => this.deleteAddress(e, id)}
                                                style={{ width: "100%", backgroundColor: "#ce0060", borderColor: "#ce0060", }}>
                                                YES
                                                <Ink duration={500} style={{ color: "#000" }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal isOpen={this.state.success_modal}
                                // onAfterOpen={afterOpenModal}
                                ariaHideApp={false} onRequestClose={(e) => this.successOpen(e)}
                                style={customStyles} contentLabel="Example Modal" >
                                <div className="container"
                                    style={{ borderRadius: "5px", height: "180px", width: "260px", zIndex: "999999999999999999999", }}>
                                    <React.Fragment>
                                        <div className="text-center">
                                            <IoCheckmarkCircle className="" style={{ fontSize: '6em', color: '#21a03e' }} />
                                        </div>
                                        <div className="px-10  pt-3 text-center fs-6">
                                            Address deleted SuccessFully
                                        </div>
                                        <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                                            <div className="text-center position-fixed" style={{ bottom: '5vw' }}>
                                                <button className="add-button-meals p-2 px-4 text-center text-white position-relative"
                                                    onClick={this.successOpen} style={{ width: "100%", backgroundColor: "#ce0060", borderColor: "#ce0060", }}  >
                                                    OK
                                                    <Ink duration={500} style={{ color: "#000" }} />
                                                </button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </Modal>
                            <Modal isOpen={this.state.change_modal}
                                // onAfterOpen={afterOpenModal}
                                ariaHideApp={false} onRequestClose={(e) => this.changeOpen(e)}
                                style={customStyles} contentLabel="Example Modal" >
                                <div className="container"
                                    style={{ borderRadius: "5px", height: "180px", width: "260px", zIndex: "999999999999999999999", }}>
                                    <React.Fragment>
                                        <div className="text-center">
                                            <IoCheckmarkCircle className="" style={{ fontSize: '6em', color: '#21a03e' }} />
                                        </div>
                                        <div className="px-10  pt-3 text-center fs-6">
                                            Address Changed SuccessFully
                                        </div>
                                        <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                                            <div className="text-center position-fixed" style={{ bottom: '5vw' }}>
                                                <button className="add-button-meals p-2 px-4 text-center text-white position-relative"
                                                    onClick={this.changeOpen} style={{ width: "100%", backgroundColor: "#ce0060", borderColor: "#ce0060", }}  >
                                                    OK
                                                    <Ink duration={500} style={{ color: "#000" }} />
                                                </button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </Modal>
                        </React.Fragment>
                    ))
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    address: state.user.allUserAddress,
});

export default connect(mapStateToProps, {
    getAllUserAddress,
    updateDefaultAddress,
    getUserDetails,
    updateUserReduxInfo,
    deleteAddress
})(MyAddress);
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #fead1d',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
