


import { createApi } from "@reduxjs/toolkit/query/react";
import { apiClient } from ".";
import { WEBSITE_URL } from "../api";





const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: WEBSITE_URL }) =>
    async ({ url, method, data, params, headers }) => {
      try {
        const result = await apiClient({
          url: url.startsWith("http://") || url.startsWith("https://") ? url : baseUrl + url,
          method,
          data,
          params,
          headers,
        })

        return { data: result?.data  }
      } catch (axiosError) {

        // const { store } = await import('@/store');
        const err = axiosError
        //   if (err.response && err.response.status === 401) {

        // }
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }



export const baseApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
})





export default axiosBaseQuery