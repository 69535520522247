import React, { useEffect, useState, useCallback } from "react";
import { GiHamburger } from "react-icons/gi";
import TopCard from "./components/TopCard.js";
import { useLazyGetTopStoresQuery } from "../../../../redux/mobile/home/homeApi.js";
import debounce from "lodash.debounce";
import { BiLoader } from "react-icons/bi";


const TopRestaurant = () => {
    const [page, setPage] = useState(1);
    const [getTopStores, { data: topStore, isLoading, isFetching }] = useLazyGetTopStoresQuery();
    const loading = isLoading;

    const latitude = JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude;
    const longitude = JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude;

    useEffect(() => {
        const fetchStores = async () => {
            try {
                await getTopStores({
                    latitude,
                    longitude,
                    page
                }).unwrap();
            } catch (err) {
                console.error("Failed to fetch stores:", err);
            }
        };
        const isEnd = topStore?.pagination?.total && page > Math.ceil(topStore?.pagination?.total / 5);
        if (!isEnd)
            fetchStores();
    }, [page,]);

    const handleHorizontalScroll = useCallback(debounce((event) => {
        const { scrollWidth, scrollLeft, clientWidth } = event.target;
        if (scrollWidth - scrollLeft - clientWidth < 1 && !loading) {
            setPage((prevPage) => prevPage + 1);
        }
    }, 200), [loading]);


    return (
        <>
            <div className="px-3 mt-3">
                <GiHamburger className="mb-2" color="FF9A62" size={20} />
                <span className="top-res-header">Top Resto For You</span>
                <div className="d-flex flex-row align-items-center">
                    <div
                        className="mt-2 hidden-scroll-bar "
                        style={{
                            display: "flex",
                            width: "100vw",
                            flexDirection: "row",
                            overflowX: "auto",
                            scrollBehavior: "smooth",
                            gap: "25px",
                        }}
                        onScroll={handleHorizontalScroll}
                    >
                        {topStore?.data?.map((store, index) => (
                            <TopCard key={index} store={store} />
                        ))}
                        {(isLoading || isFetching) && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingRight: "50px",
                                    paddingLeft: "50px",
                                    width: "20vw",
                                }}
                            >
                                <BiLoader
                                    className="loader"
                                    style={{
                                        height: "20px",
                                        width: "20px",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopRestaurant;
