import React, { Component } from 'react'
import { IoCheckmarkCircle } from 'react-icons/io5';
import { GiAlliedStar } from 'react-icons/gi';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { connect } from 'react-redux'
import noOrder from "../../../../assets/images/NoOrder.png";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE, WEBSITE_URL } from '../../../../api';
import ProgressiveImage from 'react-progressive-image'
import Countdown from "react-countdown";
import Badge from 'react-bootstrap/Badge';
import Ink from 'react-ink';
import { paymentStatusCheck } from '../../../../redux/mobile/checkOut/action';
import Modal from "react-modal";
import Truncate from 'react-truncate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../../Helpers/formatPrice';
import { FiDownload } from 'react-icons/fi';
import AgentDetails from '../Agent';
import { MdDeliveryDining } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";

export class normalOrders extends Component {
    state = {
        loading: true,
        open: false,
        unique_order_id: "",
        cancel: true,
        orders: [],
        hasMore: false,
        cancel_reason: [],
        // cancelHide: false
    };
    componentDidMount() {
        const { user, orders } = this.props;
        this.setState({ orders: orders });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orders !== this.props.orders) {
            // console.log('Orders updated:', this.props.orders);
        this.setState({ orders: this.props.orders });

            // Do something with the new orders, e.g., update the state or rerender
        }
    }
    getOrderStatus = (created_at) => {
        const date = new Date(created_at);
        const today = new Date();
        var dif = Math.abs((date.getTime() - today.getTime()) / 1000);
        // window.location.reload()
        if (dif < 60) {
            return "Cancel";
        } else {
            return "placed";
        }
    };
    getDate = (created_at) => {
        const date = new Date(created_at);
        return date;
    };
    hideCancel = (id) => {
        // ////// console.log('worked');
        // this.state.orders.find((order) => order.id === id).cancel = "timeout";
        this.forceUpdate();
    };
    checkTimeOut = (id) => {
        if (this.state.orders.find((o) => o.id === id).cancel === "timeout") {

            return false;
        }
        return true;
    };
    componentWillUnmount() {
        clearInterval(this.updateTimer);
    }
    openSheet = (order_unique_id) => {
        this.setState({
            open: true,
            unique_order_id: order_unique_id,
        });
    };

    recheckPayment = (id) => {
        this.props.paymentStatusCheck(id).then((response) => {

            if (response.data.success) {
                window.location.reload();
            } else {
                ////// console.log(response.data.message);
            }
        })
    }

    onSheetClose = () => {
        this.setState({
            open: false,
        });
    };

    callItems = (page_count) => {
        // ////// console.log('call item')
        const { user } = this.props;
        this.props
            .getUserOrders(user.data.auth_token, page_count)
            .then((response) => {
                if (response.payload.success) {
                    let orders = this.state.orders;
                    orders += response.payload.orders.data;
                    // ////// console.log(orders, "obj");
                    this.setState({
                        loading: false,
                        orders: [...this.state.orders, ...response.payload.orders.data],
                    });
                    if (
                        response.payload.orders.current_page ==
                        response.payload.orders.last_page
                    ) {
                        this.setState({ hasMore: false });
                    }
                } else {
                    this.setState({ loading: true });
                }
            });
    };

    sumStoreCharge = (store, surge, commission, tax) => {
        let sur = parseInt(surge, 10) ? parseInt(surge, 10) : 0;
        let com = parseInt(commission, 10) ? parseInt(commission, 10) : 0;
        let store_charge = store ? store : 0;
        let tax_charge = tax ? tax : 0;
        let sum = parseFloat(store_charge) + parseFloat(sur) + parseFloat(tax_charge);
        return formatPrice(sum);
    }
    printOrderBill = (order_id) => {
        const { user } = this.props;

        ////// console.log(this.props);
        window.location = WEBSITE_URL + "/print-order/" + user.data.auth_token + "/" + order_id;
    }
    render() {
        const { cancelOrder } = this.props;
        const { orders } = this.state;
        const showAgentDetailsStatuses = [3, 4, 7, 13, 5];

        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                return null;
            } else {
                return (
                    <span>
                        {seconds}s
                    </span>
                );
            }
        };
        return (
            <React.Fragment>
                <div className="px-3 " style={{ paddingBottom: '30vw' }}>
                    {orders && orders.length > 0 ?
                        orders.map((order, index) => (
                            <div className='mobile-runningorders-card p-3 mt-2' style={{ position: "relative" }}>
                                <div className="d-flex flex-row">

                                    <ProgressiveImage
                                        delay={20}
                                        src={IMAGE_BASE_URL + order?.orderitems[0]?.item?.image}
                                        placeholder={PLACE_HOLDER_IMAGE}
                                    >
                                        {(src, loading) => (
                                            <img
                                                src={src}
                                                className="" style={{ width: '22vw', borderRadius: '12px', height: '22vw', objectFit: 'cover' }}
                                                alt='main-item'
                                            />
                                        )}
                                    </ProgressiveImage>
                                    <div className="px-2 flex-grow-1 d-flex flex-column">
                                        {order?.is_prescription ?
                                            <div className='d-flex flex-row'>
                                                <div className='flex-grow-1' style={{ fontWeight: "700", fontSize: "1.1em" }}>
                                                    <Truncate lines={2}>
                                                        {order?.orderitems[0]?.name ? order?.orderitems[0]?.name : '..'}
                                                    </Truncate>
                                                </div>
                                                <div>
                                                    <Badge bg="danger">
                                                        with prescription
                                                    </Badge>
                                                </div>
                                            </div>
                                            :
                                            <span style={{ fontWeight: "700", fontSize: "1.1em" }}>{order?.orderitems[0]?.name}..</span>
                                        }

                                        <div className='text-muted' style={{ fontWeight: "500", fontSize: "1em" }}>{order?.store?.name}</div>

                                        {order?.order_status_id == 5 ? (
                                            <div className='fw-bolder'>
                                                <IoCheckmarkCircle size={20} color='#18D26B' />
                                                <span className='ps-1' style={{ color: '#18D26B' }}>Order Completed</span>
                                            </div>
                                        )
                                            : null
                                        }
                                        {/* {order?.order_status_id == 5 && !order?.rating ? (
                                            <Link to={"/place-review/" + order.id}>
                                                <span className='fw-bolder p-2' style={{ color: '#007eff' }}>
                                                    Rate your order
                                                </span>
                                            </Link>
                                        )
                                            : null
                                        } */}
                                    </div>
                                    <Link to={"/single-order/" + order.id}>
                                        <div className="btn btn-sm" style={{ fontSize: '12px', borderRadius: '8px', backgroundColor: '#fea24b', color: '#fff', fontWeight: '600' }}>
                                            View Order
                                        </div>
                                    </Link>
                                </div>
                                {showAgentDetailsStatuses.includes(order?.order_status_id) == true &&
                                    <AgentDetails
                                        name={order?.agent_name}
                                        phone={order?.agent_phone}
                                        image={order?.agent_image}
                                        hide_phone={order?.order_status_id == 5 ? true : false}
                                    />
                                }

                                <div>

                                    {order.is_schedule === 1 &&
                                        <div className="d-flex justify-content-between align-items-center mb-2" style={{ "color": "#fd8814", "border": "1px solid", "borderColor": "#fd8814", "padding": "7px 10px", "borderRadius": "10px", "marginTop": "15px", "fontWeight": "bold" }}>
                                            <div>
                                                <FaRegClock style={{ "fontSize": "19px", "marginRight": "3px" }} /> <span style={{ "fontSize": "15px" }}>Scheduled</span>
                                            </div>

                                            <div>
                                                <span>{order.schedule_date} {order.schedule_time}</span>
                                            </div>
                                        </div>
                                    }

                                    <div className="d-flex justify-content-between mt-2">
                                        <div> Order Date </div>
                                        {order?.order_placed_at == null ? <span>{moment(order?.created_at).format("YYYY-MM-DD hh:mm A")}</span> : (

                                            <span>{moment(order?.order_placed_at).format("YYYY-MM-DD hh:mm A")}</span>
                                        )}

                                    </div>

                                    <div className="d-flex justify-content-between mt-2">
                                        <div> Order ID </div>
                                        <div style={{ color: '#FD8814', fontWeight: '600' }}> #{order?.unique_order_id} </div>
                                    </div>

                                    <div className="d-flex justify-content-between mt-2">
                                        <div> Order Status </div>
                                        <div className="" style={order?.order_status_id == 6 ? { color: 'red', fontWeight: '700' } : { color: '#FD8814', fontWeight: '700' }} >{order?.order_status_id == 7 ? 'Order Preparing' : order?.orderstatus.name}</div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <span>Payment Details</span>
                                        <span>{order?.payment_mode === "COD" ? "Cash On Delivery" : order?.payment_mode === "ONLINE" ? "ONLINE" : order?.payment_mode === "WALLET" ? "WALLET" : '--'}</span>
                                    </div>
                                    {order?.order_status_id == 1 && this.checkTimeOut(order.id) && (
                                        <React.Fragment>
                                            {
                                             this.getOrderStatus(order.created_at) === "Cancel"&& (
                                                <div className="d-grid my-2">
                                                    <button
                                                        className="btn btn-danger border-radius-10 user-select-none p-2"
                                                        onClick={() => this.openSheet(order.unique_order_id)} >
                                                        Cancel Order({" "}
                                                        <Countdown
                                                            date={Date.parse(order.created_at) + 60000}
                                                            id={order.id}
                                                            renderer={renderer}
                                                            onComplete={() => this.hideCancel(order.id)}
                                                        />
                                                        )
                                                    </button>

                                                </div>
                                            ) 
                                            }
                                        </React.Fragment>
                                    )}

                                    {order?.payment_mode === "ONLINE" &&
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Payment Status</span>
                                            {order?.payment_status === "PENDING" ? (
                                                <div>
                                                    <BsExclamationCircleFill size={17} color='#ff4c4c' />
                                                    <span className='ps-1 fw-650' style={{ color: '#ff4c4c' }}>Pending</span>
                                                </div>
                                            ) : (order?.payment_status === "SUCCESS" &&
                                                <div>
                                                    <IoCheckmarkCircle size={20} color='#18D26B' />
                                                    <span className='ps-1 fw-650' style={{ color: '#18D26B' }}>Success</span>
                                                </div>
                                            )}
                                        </div>
                                    }
                                    {order?.orderitems?.map((item) => (
                                        <div className="d-flex justify-content-between mt-2">
                                            {item?.orderitemaddons.length > 0 ?
                                                item?.orderitemaddons[0]?.is_combo && item?.orderitemaddons[0]?.combo_type == "COMBO" ?
                                                    <span> {item?.name}  <span className='badge bg-info'>COMBO</span></span>
                                                    :
                                                    <span> {item?.name} <span className='badge bg-info'>ADDON</span></span>
                                                :
                                                <span> {item?.name} x {item?.quantity} {(item?.is_free_item === 1) ? '(Free Item)' : ''}</span>
                                            }
                                            <span>₹ {formatPrice(item?.price)} </span>
                                        </div>
                                    ))}
                                    <div className='text-center' style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>------------------------------------------------------</div>
                                    {order?.sub_total ?
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Item Total</span>
                                            <span>₹ {formatPrice(order?.sub_total)}</span>
                                        </div>
                                        : null}

                                    {order?.delivery_charge > 0 ?
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Delivery Charge</span>
                                            <span>₹ {formatPrice(order?.delivery_charge)}</span>
                                        </div>
                                        : (
                                            <div className="d-flex justify-content-between mt-2 ">
                                                <span>Delivery Charge</span>
                                                <span className='text-success fw-bold'>FREE DELIVERY</span>
                                            </div>
                                        )
                                    }

                                    {order?.handling_charges > 0 &&
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Handling Charge</span>
                                            <span>₹ {formatPrice(order?.handling_charges)}</span>
                                        </div>
                                    }

                                    {order?.tip_amount ?
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Tip Amount</span>
                                            <span>₹ {order?.tip_amount ? formatPrice(order?.tip_amount) : 0.00}</span>
                                        </div>
                                        : null}

                                    {order?.tax > 0 ?
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Tax & Charges</span>
                                            <span>₹ {order?.tax}</span>
                                        </div>
                                        : null}
                                    {order?.coupon_discount ?
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Coupon Discount</span>
                                            <span>- ₹ {formatPrice(order?.coupon_discount)}</span>
                                        </div>
                                        : null}
                                    <div className='text-center' style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>------------------------------------------------------</div>

                                    {order?.order_status_id == 5 &&
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Download</span>
                                            <button className='border px-2 py-1 rounded-5 bg-white' onClick={() => this.printOrderBill(order.id)} >
                                                <span className='me-2' style={{ color: "#FD8814" }}>Download Invoice</span>
                                                <FiDownload color='#FD8814' />
                                            </button>
                                        </div>
                                    }

                                    {order?.order_status_id == 6 &&
                                        <div className="d-flex justify-content-between mt-2">
                                            <span><span className='text-danger'>Cancel Reason:</span> {order?.cancellation_reason}</span>

                                        </div>
                                    }

                                    {(order?.order_status_id >= 3 && order?.order_status_id != 5 && order?.agent_phone) &&

                                        <div className="d-flex justify-content-between mb-1">
                                            <span className='mt-1'><MdDeliveryDining style={{
                                                color: '#FD8814',
                                                fontSize: '19px',
                                                marginRight: '2px'
                                            }} /> Agent: {order?.agent_name}</span>

                                            <a href={`tel:${order?.agent_phone}`} className='border px-2 py-1 rounded-5 bg-white' style={{ textDecoration: 'none' }}>
                                                <FaPhoneAlt style={{
                                                    color: '#FD8814',
                                                    marginRight: '5px',
                                                    fontSize: '11px'
                                                }} />
                                                <span className='me-2' style={{ color: "#FD8814" }}>{order?.agent_phone}</span>
                                            </a>
                                        </div>
                                    }


                                    <div className="d-flex justify-content-between mt-2 pb-2">
                                        <span className='fw-bolder fs-6'>Total Amount</span>
                                        <span className='fw-bolder fs-6'>₹ {formatPrice(order?.total ? order?.total : 0)}</span>
                                    </div>
                                    <div className=' d-flex flex-column'>
                                        {order?.order_status_id == 8 && moment(order?.created_at).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? (
                                            <React.Fragment>

                                                <div className="d-grid">
                                                    <button
                                                        className="btn border-radius-10 user-select-none p-2 text-white" style={{
                                                            background: '#FD8814',
                                                        }}
                                                        onClick={() => {
                                                            this.recheckPayment(order.id)
                                                        }} >
                                                        Re-check Payment
                                                    </button>

                                                </div>
                                            </React.Fragment>
                                        ) : ("")
                                        }
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <div className='d-flex flex-column text-center pt-4'>
                                <img
                                    src={noOrder}
                                    className="" style={{ width: '100%', borderRadius: '12px' }}
                                    alt='main-item'
                                />
                                <div style={{ fontSize: '1.2em', fontWeight: '700' }}>No Order Found</div>
                            </div>
                        )
                    }
                </div>
                <Modal
                    isOpen={this.state.open}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={this.onSheetClose}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{ borderRadius: "5px", height: "145px", zIndex: '999999999999999999999' }}
                    >
                        <React.Fragment>
                            <div className="px-10 col-12 pb-3 d-flex justify-content-between align-items-center text-center">
                                <span className="mt-2 mb-0" style={{ fontSize: '1.3em', fontWeight: '700' }}>
                                    Would you like to cancel this order ?
                                </span>
                            </div>

                            <div className="d-flex justify-content-around w-100 gap-4  mt-3">
                                <div className="text-center mr-4 w-100">
                                    <button
                                        className="delete-button-meals p-2 px-2 w-100 position-relative"
                                        style={{
                                            borderColor: "#fd8814",
                                            color: "#fd8814",
                                            // border: '1px solid #fd8814',
                                            width: "100%",
                                        }} onClick={this.onSheetClose} >
                                        Close
                                        <Ink duration={500} style={{ color: "#000" }} />
                                    </button>
                                </div>

                                <div className="text-center w-100">
                                    <button
                                        className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#ce0060",
                                            borderColor: "#ce0060",
                                        }} onClick={() =>
                                            cancelOrder(this.state.unique_order_id)} >
                                        Confirm
                                        <Ink duration={500} style={{ color: "#000" }} />
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
})
export default connect(mapStateToProps, {
    paymentStatusCheck
})(normalOrders)

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #fead1d',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
