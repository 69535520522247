import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import Profile from "../../../assets/images/profile-img.png";
import profileEdit from "../../../assets/images/Profile-edit.png";
import { MdOutlineLogout, MdOutlineCardMembership, MdNoAccounts } from "react-icons/md";
import { BiWallet } from "react-icons/bi";
import { BsCash, BsPersonBadge } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { RiMedalLine, RiCoupon2Line } from "react-icons/ri";
import { Link, Redirect } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { getUserDetails, deleteUser, logoutUser } from "../../../redux/mobile/user/action";
import { GET_PAGES_URL, IMAGE_BASE_URL } from "../../../api";
import Modal from "react-modal";
import Ink from "react-ink";
import Axios from "axios";
import PagePopup from "./PagePopup";
import { HiOutlineChevronLeft } from "react-icons/hi";

export class MyProfile extends Component {
    state = {
        loading: false,
        open: false,
        open_logout: false,
        pages: []
    };
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.getUserDetails(user.data.auth_token).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false });
            } else {
                ////// console.log("API error")
            }
        })

        Axios.post(GET_PAGES_URL).then((response) => {
            ////// console.log(response);
            this.setState({ pages: response?.data })
        });
    }

    handleAccountDelete = (e) => {
        e.preventDefault();
        this.setState({ open: !this.state.open })
    }

    handleAccountLogout = (e) => {
        e.preventDefault();
        this.setState({ open_logout: !this.state.open_logout })
    }

    __handleDelete = (e) => {
        e.preventDefault();
        const { user } = this.props;
        this.props.deleteUser(user?.data?.auth_token).then((response) => {
            // ////// console.log(response)
            if (response && response.payload && response.payload.success) {
                window.location.replace("/signup");
            }
        });
    }




    __handleLogout = (e) => {
        e.preventDefault();
        const { user } = this.props;
        this.props.logoutUser(user.data.auth_token).then((response) => {
            // ////// console.log(response)
            if (response && response.payload && response.payload.success) {
                window.location.replace("/signup");
            }
        });


        if(window.ReactNativeWebView) {
            const response = {
                type: 'onesignal_logout',
                data: user?.data?.id
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
    }

    render() {

        const { user } = this.props;
        const { userDetails } = this.props;
        if (user?.data?.auth_token == undefined || !user.success ) {

            return <Redirect to={"/signup"} />;


        }
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="15" y="25" rx="8" ry="8" width="30" height="30" />
                            <rect x="105" y="30" rx="8" ry="8" width="100" height="20" />
                            <rect x="100" y="80" rx="40" ry="40" width="85" height="85" />
                            <rect x="15" y="185" rx="8" ry="8" width="80" height="20" />
                            <rect x="15" y="220" rx="8" ry="8" width="50" height="25" />
                            <rect x="250" y="220" rx="8" ry="8" width="20" height="20" />
                            <rect x="15" y="250" rx="8" ry="8" width="90" height="15" />
                            <rect x="15" y="280" rx="8" ry="8" width="250" />
                            <rect x="15" y="340" rx="8" ry="8" width="250" height="40" />
                            <rect x="15" y="400" rx="8" ry="8" width="250" height="40" />
                            <rect x="15" y="460" rx="8" ry="8" width="250" height="40" />
                            <rect x="15" y="520" rx="8" ry="8" width="250" height="40" />
                            <rect x="15" y="580" rx="8" ry="8" width="250" height="40" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                        <div
                            className="d-flex align-items-center px-4 w-100 py-5"
                            style={{
                                top: "0px",
                                // zIndex: "99999999",
                            }}
                        >
                            <div
                                className="px-2 pb-1 back-button"
                                onClick={() => this.props.history.push('/home')}
                            >
                                <span className="fs-2 back-button-icon">
                                    <HiOutlineChevronLeft
                                        style={{ color: "#DA6317" }}
                                    />
                                </span>
                            </div>

                            <div className=" mr-3 mt-3 mobile-back-button-title fs-5">
                                <span className="mt-3 " style={{ marginLeft:'34px' }}>{'My Profile'}</span>
                            </div>
                        </div>
                        </div>
                        <div className="d-flex flex-column align-items-center "  >
                            {userDetails && userDetails.data && userDetails.data.image ? (
                                <img
                                    src={
                                        IMAGE_BASE_URL + userDetails?.data?.image
                                    }
                                    alt=""
                                    height={118}
                                    width={118}
                                    className="rounded-circle"
                                />
                            ) : (
                                <img
                                    src={Profile}
                                    alt=""
                                    height={118}
                                    width={118}
                                    className="rounded-circle"
                                />
                            )}
                        </div>

                        {/* <div className="px-3 pt-4">
                            <span className="membership-color b-r-10 px-2 py-1 ">
                                Member Gold
                            </span>
                        </div> */}

                        <div className="px-3 pt-3 d-flex justify-content-between">
                            <span className="profile-name-header  ml-2">
                                {userDetails?.data?.name}
                            </span>
                            <Link to={"/edit-profile"}>
                                <div className="pt-2">
                                    <img
                                        src={profileEdit}
                                        alt=""
                                        height={24}
                                        width={24}
                                    />
                                </div>
                            </Link>
                        </div>
                        <div className="px-3">
                            <span className="text-muted">
                                +91 {userDetails?.data?.phone}
                            </span>
                        </div>

                        <div
                            className="px-3 pt-3"
                            style={{ paddingBottom: "8vw" }}
                        >
                            <Link to="/my-coupon">
                                <div className="d-flex profile-opt py-3 px-3 ">
                                    <div>
                                        <RiCoupon2Line className="profile-btns" />
                                    </div>
                                    <div className="ms-4">
                                        {" "}
                                        <span className="profile-tag">
                                            Coupons
                                        </span>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/refer-and-earn">
                                <div className="d-flex profile-opt py-3 mt-3 px-3 ">
                                    <div>
                                        <BsCash className="profile-btns" />
                                    </div>
                                    <div className="ms-4">
                                        {" "}
                                        <span className="profile-tag">
                                            Refer and Earn
                                        </span>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/my-address">
                                <div className="d-flex profile-opt py-3 mt-3 px-3 ">
                                    <div>
                                        <GoLocation className="profile-btns" />
                                    </div>
                                    <div className="ms-4">
                                        {" "}
                                        <span className="profile-tag">
                                            Address
                                        </span>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/subscription-plans">
                                <div className="d-flex profile-opt py-3 mt-3 px-3 ">
                                    <div>
                                        <MdOutlineCardMembership className="profile-btns" />
                                    </div>
                                    <div className="ms-4">
                                        {" "}
                                        <span className="profile-tag">
                                            Subscriptions
                                        </span>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/my-rewards">
                                <div className="d-flex profile-opt py-3 mt-3 px-3 ">
                                    <div>
                                        <RiMedalLine className="profile-btns" />
                                    </div>
                                    <div className="ms-4">
                                        {" "}
                                        <span className="profile-tag">
                                            My Rewards
                                        </span>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/running-orders/normal">
                                <div className="d-flex profile-opt py-3 mt-3 px-3 ">
                                    <div>
                                        <BsPersonBadge className="profile-btns" />
                                    </div>
                                    <div className="ms-4">
                                        {" "}
                                        <span className="profile-tag">
                                            My Orders
                                        </span>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/wallet">
                                <div className="d-flex profile-opt py-3 mt-3 px-3">
                                    <div>
                                        <BiWallet className="profile-btns" />
                                    </div>
                                    <div className="ms-4">
                                        {" "}
                                        <span className="profile-tag">
                                            My Wallet
                                        </span>
                                    </div>
                                </div>
                            </Link>


                            {this.state.pages.map(page => (
                                <div key={page.id} >
                                    <PagePopup page={page}></PagePopup>
                                </div>
                            ))}
                            <div onClick={(e) => this.handleAccountLogout(e)}>
                                <div className="d-flex profile-opt py-3 mt-3 px-3">
                                    <div>
                                        <MdOutlineLogout className="profile-btns" />
                                    </div>
                                    <div className="ms-4">
                                        {" "}
                                        <span className="profile-tag">
                                            Logout
                                        </span>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <Modal
                            isOpen={this.state.open}
                            // onAfterOpen={afterOpenModal}
                            ariaHideApp={false}
                            onRequestClose={() => this.handlePopup()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >

                        </Modal>
                        <Modal
                            isOpen={this.state.open_logout}
                            // onAfterOpen={afterOpenModal}
                            ariaHideApp={false}
                            onRequestClose={() => this.handlePopup()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div className='d-flex flex-column align-items-center' style={{ backgroundColor: '#fff', padding: '13px' }}>
                                <div className="text-center">
                                    <span className='text-danger fs-4 fw-semibold'>Logout?</span>
                                </div>
                                <div className='text-muted ms-4 mt-3'>
                                    <span>Are you sure you anout logging out ?</span>
                                </div>

                                <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                                    <div className="text-center mr-4 w-100">
                                        <button className="delete-button-meals p-2 px-2 w-100 position-relative"
                                            onClick={(e) => this.handleAccountLogout(e)}
                                            style={{
                                                borderColor: "#fd8814", color: "#fd8814", border: '1px solid #fd8814', width: "100%",
                                            }}>  NO
                                            <Ink duration={500} style={{ color: "#000" }} />
                                        </button>
                                    </div>

                                    <div className="text-center w-100">
                                        <button className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                            onClick={(e) => this.__handleLogout(e)}
                                            style={{ width: "100%", backgroundColor: "#ce0060", borderColor: "#ce0060", }}>
                                            YES
                                            <Ink duration={500} style={{ color: "#000" }} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/* <MobileFooter active_store={true} /> */}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userDetails: state.user.userDetails,
});

    export default connect(mapStateToProps, {
    getUserDetails,
    deleteUser,
    logoutUser
})(MyProfile);
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #fead1d',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
