import React, { Component } from 'react'
import animationData from '../../assets/lottie/loader.json';
import Lottie from 'react-lottie';
import { withRouter } from 'react-router-dom';
export class loading extends Component {
    render() {
        const { full_loading } = this.props; // Receiving the prop

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <React.Fragment>

                <div className="d-flex align-items-center bg-white justify-content-center"
                 style={{paddingTop:'180px'}}>
                    <Lottie
                        options={defaultOptions}
                        height={250}
                        width={250}
                    />
                </div>
                {full_loading && (
                    <div className="d-flex align-items-center bg-white justify-content-center">
                        Location Fetching, Be in Proper Network
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter((loading));
