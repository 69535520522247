import React, { Component } from "react";
import { connect } from "react-redux";
import VendorFooter from "../VendorFooter";
import Chart from "react-apexcharts";
import ProgressBar from "react-bootstrap/ProgressBar";
import pay from "../../../assets/images/payout.png";
import paytime from "../../../assets/images/payout time.png";
import { getVendorReportData } from "../../../redux/vendor/user/action";
import { viewPayout } from "../../../redux/vendor/payout/action";
import { Link } from "react-router-dom";
import Loading from "../../Elements/loading";
import moment from "moment";
import { AiFillStar } from "react-icons/ai";

export class VendorBusinessReport extends Component {
    state = {
        data: [],
        from: "",
        to: "",
        type: "today",
        loadiing: false,
        options: {
            colors: ["#FEA24B", "#F5D73E", "#FF5F00"],
            legend: {
                show: true,
                customLegendItems: ["Order Placed", "Dispatched", "Cancelled"],
                position: "bottom",
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    customScale: 0.9,
                    donut: {
                        size: "79%",
                    },
                },
            },

            tooltip: {
                enabled: false,
            },
        },
        progress: 0,
        completed_count: 0,
        placed_count: 0,
    };
    handleReport(day) {
        const { user } = this.props;
        this.setState({ loading: true });
        var from = new Date();
        if (day === "today") {
            if (user && user.data && user.data.auth_token) {
                this.props
                    .getVendorReportData(
                        user.data.auth_token,
                        new Date(),
                        new Date()
                    )
                    .then((response) => {
                        //// console.log(response.data);
                        if (response.data) {
                            this.setState({
                                data: response.data,
                                loading: false,
                                type: "today",
                                from: new Date().toDateString(),
                                to: null,
                            });
                        }
                    });
            }
        } else if (day === "week") {
            if (user && user.data && user.data.auth_token) {
                // const from = new Date().setDate(new Date().getDate() - 7).toString()
                from.setDate(from.getDate() - 7);
                this.props
                    .getVendorReportData(user.data.auth_token, from, new Date())
                    .then((response) => {
                        //// console.log(response.data);
                        if (response.data) {
                            this.setState({
                                data: response.data,
                                loading: false,
                                type: "week",
                                from: from.toDateString(),
                                to: new Date().toDateString(),
                            });
                        }
                    });
            }
        } else {
            if (user && user.data && user.data.auth_token) {
                from.setDate(from.getDate() - 30);
                this.props
                    .getVendorReportData(user.data.auth_token, from, new Date())
                    .then((response) => {
                        //// console.log(response.data);
                        if (response.data) {
                            this.setState({
                                data: response.data,
                                loading: false,
                                type: "month",
                                from: from.toDateString(),
                                to: new Date().toDateString(),
                            });
                        }
                    });
            }
        }
        this.props.viewPayout(user.data.auth_token, from, new Date());
    }
    componentDidMount() {
        const { user } = this.props;
        var from = new Date();
        this.setState({ loading: true });
        if (user && user.data && user.data.auth_token) {
            this.props
                .getVendorReportData(
                    user.data.auth_token,
                    new Date(),
                    new Date()
                )
                .then((response) => {
                    //// console.log(response.data);
                    if (response.data) {
                        this.setState({
                            data: response.data,
                            loading: false,
                            from: new Date().toDateString(),
                        });
                    }
                });
        }
        this.props.viewPayout(user.data.auth_token, from, new Date());
    }
    render() {
        let series = [];
        series.push(this.state.data.placed_count);
        series.push(this.state.data.dispatched_count);
        series.push(this.state.data.cancelled_count);

        // //// console.log(todaydate.toDateString())
        const { store } = this.props;
        const { payout } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div style={{ paddingBottom: "35vw" }} className="px-1">
                            <div className="report-title px-3 mt-4 pt-1">
                                Your Business Reports
                            </div>

                            <div className="px-2 mt-3">
                                <div className="">
                                    <div className="d-flex justify-content-between gap-2">
                                        <button
                                            className={
                                                this.state.type == "today"
                                                    ? " buisness-date-btn-active text-white "
                                                    : "buisness-date-btn"
                                            }
                                            onClick={() =>
                                                this.handleReport("today")
                                            }
                                        >
                                            Today
                                        </button>
                                        <button
                                            className={
                                                this.state.type == "week"
                                                    ? " buisness-date-btn-active text-white  "
                                                    : "buisness-date-btn "
                                            }
                                            onClick={() =>
                                                this.handleReport("week")
                                            }
                                        >
                                            This week
                                        </button>
                                        <button
                                            className={
                                                this.state.type == "month"
                                                    ? " buisness-date-btn-active text-white "
                                                    : "buisness-date-btn"
                                            }
                                            onClick={() =>
                                                this.handleReport("month")
                                            }
                                        >
                                            This month
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div className="text-muted px-3 pt-3">
                                        <span>
                                            {this.state.type == "today"
                                                ? this.state.from
                                                : this.state.from +
                                                  " - " +
                                                  this.state.to}
                                        </span>
                                    </div>
                                    <div
                                        className=" px-3 "
                                        style={{
                                            fontWeight: "700",
                                            fontSize: "17px",
                                        }}
                                    >
                                        <span>Top Insights</span>
                                    </div>
                                </div>
                                <div className="mt-1 px-3">
                                    <div className=" graph-progress ">
                                        <div className="py-3 px-2">
                                            <span className="text-start mb-2 graph-header">
                                                Order Accepted,Completed
                                            </span>
                                            <div className="mt-2 px-1">
                                                <ProgressBar
                                                    variant="warning"
                                                    now={
                                                        this.state.data.progress
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {this.state.data.completed_count ? (
                                            <div className="donut position-relative">
                                                <span className="graph-header px-2 pt-2">
                                                    Order Accepted
                                                </span>
                                                <Chart
                                                    options={this.state.options}
                                                    series={series}
                                                    type="donut"
                                                />
                                                <div
                                                    className="position-absolute"
                                                    style={{
                                                        top: "45%",
                                                        left: "45%",
                                                    }}
                                                >
                                                    {" "}
                                                    {
                                                        this.state.data
                                                            .completed_count
                                                    }{" "}
                                                    Orders <br /> Delivered
                                                </div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div className="px-3 py-3 ">
                                            <div className="py-1">
                                                <span
                                                    style={{ color: "#32BA7C" }}
                                                >
                                                    {" "}
                                                    {
                                                        this.state.data
                                                            .accepted_count
                                                    }{" "}
                                                    Orders Accepted
                                                </span>
                                            </div>
                                            <div className="py-1">
                                                <span className="text-muted">
                                                    {
                                                        this.state.data
                                                            .completed_count
                                                    }{" "}
                                                    Completed
                                                </span>
                                            </div>
                                            <div className="py-1">
                                                <span className="text-muted">
                                                    {
                                                        this.state.data
                                                            .transaction_success_count
                                                    }{" "}
                                                    Transactions Success
                                                </span>
                                            </div>
                                            <div className="py-1">
                                                <span className="text-danger">
                                                    {
                                                        this.state.data
                                                            .cancelled_count
                                                    }{" "}
                                                    Orders Cancelled
                                                </span>
                                            </div>
                                            <div className="py-2">
                                                <Link
                                                    to={
                                                        "/vendor-past-order/" +
                                                        store.store.id
                                                    }
                                                >
                                                    <button className="graph-past-order-btn py-2">
                                                        See Past Orders
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state?.data?.show_payout == 1 && (
                                        <React.Fragment>
                                            <div>
                                                <div className="pt-3 pb-2">
                                                    <span
                                                        style={{
                                                            color: "#09051C",
                                                            fontWeight: "700",
                                                            fontSize: "17px",
                                                        }}
                                                    >
                                                        Payout Details
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    borderTop:
                                                        "1px dashed #cacaca",
                                                    borderBottom:
                                                        "1px dashed #cacaca",
                                                }}
                                            >
                                                <div className="d-flex justify-content-between fw-bolder my-2">
                                                    <div className="d-flex flex-column">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            Grosav Payment Cycle
                                                        </div>
                                                        <div className="">
                                                            {
                                                                this.state.data
                                                                    ?.currentPayoutCycle
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-end">
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            Upcoming payment
                                                            date
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="10"
                                                                height="10"
                                                                fill="gray"
                                                                className="bi bi-exclamation-circle-fill ms-1"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                                                            </svg>
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "15px",
                                                            }}
                                                        >
                                                            {
                                                                this.state.data
                                                                    ?.nextPayoutDate
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between mt-3">
                                                    <div className="d-flex flex-column fw-bolder">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            Payment for current
                                                            week*
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "25px",
                                                                fontWeight:
                                                                    "800",
                                                                color: "#228c18",
                                                            }}
                                                        >
                                                            ₹
                                                            {this.state.data
                                                                ?.payoutCurrentCycle
                                                                ? this.state
                                                                      .data
                                                                      ?.payoutCurrentCycle
                                                                : "0.00"}
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-column fw-bolder">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            All Pending Payouts*
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "25px",
                                                                fontWeight:
                                                                    "800",
                                                                color: "#228c18",
                                                            }}
                                                        >
                                                            ₹
                                                            {this.state.data
                                                                ?.pendingPayouts
                                                                ? this.state
                                                                      .data
                                                                      ?.pendingPayouts
                                                                : "0.00"}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between mt-3">
                                                    <div className="d-flex flex-column fw-bolder">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            Running week
                                                            payments*
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "25px",
                                                                fontWeight:
                                                                    "800",
                                                                color: "#228c18",
                                                            }}
                                                        >
                                                            ₹
                                                            {this.state.data
                                                                ?.nextPayout
                                                                ? this.state
                                                                      .data
                                                                      ?.nextPayout
                                                                : "0.00"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    <div className="payout-details py-1 mt-2">
                                        {/* <div className="px-3 payout-details-header pt-2">
                                            Completed Payouts
                                        </div>
                                        <div className="d-flex flex-cloumn justify-content-between px-4">
                                            <div className="text-muted">
                                                Rs. {payout?.completedPayout ? payout?.completedPayout : "0"}
                                            </div>
                                            <div>
                                                <img src={pay} alt="" height={20} width={22} />
                                            </div>
                                        </div>
                                        <div className="px-3 payout-details-header pt-3">
                                            Pending Payouts
                                        </div>
                                        <div className="d-flex flex-cloumn justify-content-between  px-4">
                                            <div className="text-muted">
                                                Rs. {payout?.pendingPayout ? payout?.pendingPayout : '0'}
                                            </div>
                                            <div>
                                                <img src={paytime} alt="" height={15} width={16} />
                                            </div>
                                        </div> */}
                                        <Link to="/vendor-payout-details">
                                            <div className="py-2 px-3">
                                                <button className="graph-past-order-btn py-2">
                                                    Click To See Detailed Payout
                                                    Details
                                                </button>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="px-3 pt-3">
                                    <span
                                        style={{
                                            fontWeight: "700",
                                            fontSize: "17px",
                                        }}
                                    >
                                        Date Wise Sales
                                    </span>
                                    <div className="py-2">
                                        <Link to="/vendor-datewise-sale">
                                            <button className="date-wise-sale-btn">
                                                View Date Wise Sales
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="pt-2">
                                        <span
                                            style={{
                                                fontWeight: "700",
                                                fontSize: "17px",
                                            }}
                                        >
                                            Customer Ratings
                                        </span>
                                    </div>
                                    <span className="text-muted">
                                        Feedback is shown for last 10 Orders
                                    </span>
                                </div>
                                <div className="px-3 mt-2">
                                    <div className="rating px-3 pt-2">
                                        <div className="payout-details-header">
                                            <span>Customer Ratings</span>
                                        </div>
                                        <div>
                                            <span className="rating-header">
                                                {this.state.data.avgRating}
                                            </span>{" "}
                                            <AiFillStar className="star-rating" />
                                        </div>
                                        <div className="d-flex flex-row align-items-center ">
                                            <div className="d-flex ratings-scroll hidden-scroll-bar">
                                                {this.state.data &&
                                                    this.state.data.reviews &&
                                                    this.state.data.reviews.map(
                                                        (review, index) => (
                                                            <div
                                                                className="mt-3 px-4 rating-des pb-5"
                                                                style={{
                                                                    minWidth:
                                                                        "80vw",
                                                                    height: "auto",
                                                                }}
                                                            >
                                                                <div className="d-flex justify-content-between px-1 pt-3">
                                                                    <div>
                                                                        {review &&
                                                                        review.user &&
                                                                        review
                                                                            .user
                                                                            .name
                                                                            ? review
                                                                                  .user
                                                                                  .name
                                                                            : "Deleted User"}
                                                                    </div>
                                                                    <div className="text-end rating-header">
                                                                        {" "}
                                                                        <AiFillStar className="star-rating" />
                                                                        {review &&
                                                                        review.rating
                                                                            ? review.rating
                                                                            : "No rating"}
                                                                    </div>
                                                                </div>
                                                                <div className="py-4 text-muted px-1">
                                                                    <span>
                                                                        {review &&
                                                                        review.review
                                                                            ? review.review
                                                                            : "No review"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <VendorFooter active_reports={true} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    store: state.vendor.store,
    payout: state.payouts.payouts,
});

export default connect(mapStateToProps, { getVendorReportData, viewPayout })(
    VendorBusinessReport
);
