import React, { Component } from "react";
import { connect } from "react-redux";
import {
    updateCart,
    prescriptionDelete,
    updateStore,
    clearCartItems,
} from "../../../../redux/mobile/cart/action";
import { removeCoupon } from "../../../../redux/mobile/mobileCoupon/action";
import { BottomSheet } from "react-spring-bottom-sheet";
import Confetti from "react-confetti";
import Modal from "react-modal";
import Ink from "react-ink";
import Truncate from 'react-truncate';
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { formatPrice } from "../../../Helpers/formatPrice";
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";

class FloatCart extends Component {
    state = {
        isOpen: false,
        removeProductFromPreviousRestaurant: false,
        product: [],
        notAdd: "",
        open: false,
        confetti: false,
        free_item_added: false,
    };

    componentDidMount() {
        const { cartProducts, user, storeInfo } = this.props;
        if (cartProducts.length) {
            this.setState({ isOpen: true });
        } else if (user?.runningOrders) {
            this.setState({ isOpen: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.newProduct !== this.props.newProduct) {
            this.addProduct(nextProps.newProduct);
            // ////// console.log('123')
        }
        if (nextProps.productToRemove !== this.props.productToRemove) {
            this.removeProduct(nextProps.productToRemove);
        }
    }

    openFloatCart = () => {
        this.setState({ isOpen: true });
    };

    closeFloatCart = () => {
        if (this.props?.user?.runningOrders) {
            this.setState({ isOpen: true });
        } else {
            this.setState({ isOpen: false });
        }
    };

    handlePopup = () => {
        this.setState({ open: !this.state.open });
    };

    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };

    addProduct = (product) => {
        this.forceUpdate()
        const { cartProducts, updateCart, updateStore, user, storeInfo, cartTotal } = this.props;
        //get restaurant id and save to localStorage as active restaurant
        localStorage.setItem("cleared", "false");
        let productAlreadyInCart = false;
        let differentRestaurant = false;
        let cartUpdateflag = true;
        cartProducts.forEach((cp) => {
            if (cartUpdateflag) {
                if (product.store_id === cp.store_id) {
                    localStorage.setItem("activeRestaurant", product.store_id);
                    let store_new = product?.store;
                    ////// console.log(store_new, 'update storeeee')
                    updateStore(store_new);
                }

                // first check if the restaurent id matches with items in cart
                // if restaurant id doesn't match, then remove all products from cart
                // then continue to add the new product to cart
                if (cp.store_id === product.store_id) {
                    // then add the item to cart or increment count
                    if (cp.id === product.id) {
                        //check if product has customizations, and if the customization matches with any
                        if (
                            JSON.stringify(cp.selectedaddons) ===
                            JSON.stringify(product.selectedaddons)
                        ) {
                            // increment the item quantity by 1
                            // if (cp.quantity < product.stock) {
                            cp.quantity += 1;
                            // }
                            productAlreadyInCart = true;
                            differentRestaurant = false;
                        }
                    }
                } else {
                    this.setState({
                        removeProductFromPreviousRestaurant: true,
                        open: true,
                    });
                    differentRestaurant = true;
                    cartUpdateflag = false;
                    this.setState({ product: product });
                }
            }
        });
        let storeData = [];
        // if (product.stock > 0) {
        if (!productAlreadyInCart && !differentRestaurant) {
            // alert(product.quantity);
            localStorage.setItem("activeRestaurant", product.store_id);
            cartProducts.push(product);
            let store_new = product?.store;
            updateStore(store_new);
            this.forceStateUpdate();
            storeData = store_new;
        } else {
            storeData = storeInfo;
        }
        // if (cartUpdateflag) {
        this.forceStateUpdate();
        const isFreeItemActive = storeData?.is_free_item === 1;
        const newTotalPrice = cartProducts.reduce((total, item) => total + this.superSum(item), 0);

        const newTotalPriceWithoutCombo = cartProducts.reduce((total, item) => {
            if (item?.is_combo == 0 && item?.combo_categories?.length == 0) {
                ////// console.log(total, this.superSum(item), 'superSum')
                return total + this.superSum(item);
            }
            return total;
        }, 0);
        const hasReachedMinSubtotal = newTotalPriceWithoutCombo >= parseFloat(storeData?.free_item_min_subtotal);

        if (storeData?.is_free_item == 1 && storeData?.free_item_min_subtotal > 0 && isFreeItemActive && hasReachedMinSubtotal) {
            this.setState({
                remainingAmount: 0,
                remainingPopup: false
            })
            // Add free product to cart if not already added
            if (cartProducts.find(cp => cp.id === storeData?.free_item_id) === undefined) {
                if (storeData?.free_product) {
                    storeData.free_product.quantity = 1;
                    cartProducts.push(storeData.free_product);
                }

                this.setState({ confetti: true, free_item_added: true });
                setTimeout(() => {
                    this.setState({ confetti: false, free_item_added: false });
                }, 3000);
            }
        } else {
            let remainingAmount = newTotalPriceWithoutCombo;
            if (storeData?.is_free_item == 1 && storeData?.free_item_min_subtotal > 0) {
                remainingAmount = parseFloat(storeData?.free_item_min_subtotal) - newTotalPriceWithoutCombo;
                this.setState({
                    remainingPopup: true
                })
            }
            this.setState({
                remainingAmount: remainingAmount,
            })
        }
        //// console.log('====================================');
        //// console.log(cartProducts,'cartProducts');
        //// console.log('====================================');
        updateCart(cartProducts);

        // }
        this.openFloatCart();
        // }
    };



    __addonSum = (item) => {
        let data = item?.selectedaddons;
        let sum = 0;
        data?.forEach((i) => {
            sum += parseFloat(i.price * i.quantity + item?.selling_price * i.quantity)
        });
        let subtotal = sum;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price * item?.quantity;
        return formatPrice(total);
    }


    __comboSum = (item) => {
        let data = item?.selectedcombos;
        let sum = 0;
        data?.forEach((i) => {
            sum += parseFloat(i.price)
        });
        let subtotal = sum + item?.selling_price;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price * item?.quantity;
        return formatPrice(total);
    }

    superSum = (item) => {

        if (item.selectedcombos?.length > 0) {
            return parseFloat(this.__comboSum(item));
        }

        if (item.selectedaddons?.length > 0) {
            return parseFloat(this.__addonSum(item));
        }

        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = item?.selling_price - parseFloat(item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = item.selling_price;
        }
        return sell_price * item.quantity;
    }

    removeProduct = (product) => {
        const { cartProducts, updateCart, updateStore, user, storeInfo, cartTotal } = this.props;

        const index = cartProducts.findIndex((p) => p.id === product.id);

        //if product is in the cart then index will be greater than 0
        if (index >= 0) {
            cartProducts.forEach((cp) => {
                if (cp.id === product.id) {
                    if (cp.quantity === 1) {
                        //if quantity is 1 then remove product from cart
                        cartProducts.splice(index, 1);
                    } else {
                        cp.quantity -= product.quantity;
                    }
                }
            });



            if (storeInfo?.is_free_item == 1) {
                const newTotalPriceWithoutCombo = cartProducts.reduce((total, item) => {
                    if (item?.is_combo == 0 && item?.combo_categories?.length == 0) {
                        return total + parseInt(this.superSum(item));
                    }
                    return total;
                }, 0);
                if (newTotalPriceWithoutCombo < parseFloat(storeInfo?.free_item_min_subtotal)) {
                    const freeItemIndex = cartProducts.findIndex((cp) => cp.id === storeInfo?.free_item_id);
                    if (freeItemIndex >= 0) {
                        cartProducts.splice(freeItemIndex, 1);
                    }

                    const remainingAmount = parseFloat(storeInfo?.free_item_min_subtotal) - newTotalPriceWithoutCombo;
                    this.setState({
                        remainingAmount,
                        remainingPopup: true
                    })
                }
            }


            updateCart(cartProducts);

            if (cartProducts.length < 1) {
                this.closeFloatCart();
                localStorage.removeItem("activeRestaurant");
            }
        }
    };

    clearCart = () => {
        const { cartProducts, updateCart, user } = this.props;
        // this.props.clearCartItems();
        cartProducts.splice(0, cartProducts.length)
        // this.props.clearCartItems();
        this.forceStateUpdate();
        this.closeFloatCart();
        // setTimeout(() => {
        //     updateCart(cartProducts);
        // }, 1);
        // removeCoupon();
        localStorage.setItem("activeRestaurant", this.state.product.store_id);
        this.props.removeCoupon();
        this.props.prescriptionDelete(user?.data?.auth_token);
        //   setTimeout(() => {
        let store_new = this.state.product?.store;
        updateStore(store_new);
        this.addProduct(this.state.product);
        this.openFloatCart();
        // cartProducts.push(this.state.product);
        // updateCart(cartProducts);
        // }, 1);

        this.setState({
            open: false,
            product: [],
            removeProductFromPreviousRestaurant: false,
            remainingPopup: false,
            free_item_added: false,
        });
        this.forceStateUpdate();
        localStorage.setItem("cleared", "true");
        this.setState({ confetti: true });
        setTimeout(() => {
            this.setState({ confetti: false });
        }, 3000);
    };

    itemCount = () => {
        const { cartProducts } = this.props;
        let count = 0
        if (cartProducts?.length > 0) {
            cartProducts?.map((orderItem) => {
                if (orderItem?.selectedaddons?.length > 0) {
                    count += orderItem.selectedaddons.length;
                } else {
                    count += 1;
                }
            })
            return parseInt(count);
        } else {
            return count;
        }
    }

    // getTotalItemsInCart = () => {
    //     const { cartTotal } = this.props;
    //     // if (localStorage.getItem("countQuantityAsTotalItemsOnCart") === "true") {
    //     // 	let total = 0;
    //     // 	this.props.cartProducts.forEach((item) => {
    //     // 		total += item.quantity;
    //     // 	});
    //     // 	return total;
    //     // } else {
    //     return cartTotal.productQuantity;
    //     // }
    // };
    render() {
        const { cartTotal, cartProducts, float, isRunning, user, storeInfo, noFooter, browseOn, singleProduct } = this.props;
        let classes = ["float-cart"];
        if (!!this.state.isOpen) {
            if (browseOn) {
                classes.push("float-cart-browse");
            } else if (singleProduct) {
                classes.push("float-cart-product");
            } else if (noFooter) {
                classes.push("float-cart-no-footer");
            } else {
                classes.push("float-cart--open");
            }
        }

        return (
            <React.Fragment>
                {this.state.confetti && (
                    <Confetti
                        width={500}
                        confettiSource={{ x: 30, y: 90, w: 500, h: 5000 }}
                        gravity={0.3}
                        friction={0.99}
                        numberOfPieces={500}
                        height={5000}
                    />
                )}
                {this.state.removeProductFromPreviousRestaurant && (
                    <React.Fragment>
                        {/* {////// console.log(
                            this.state.removeProductFromPreviousRestaurant,
                            this.state.open
                            , 'removeProductFromPreviousRestaurant')} */}
                        <Modal
                            isOpen={this.state.open}
                            // onAfterOpen={afterOpenModal}
                            ariaHideApp={false}
                            onRequestClose={() => this.handlePopup()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div
                                className="container"
                                style={{
                                    borderRadius: "5px",
                                    height: "210px",
                                    zIndex: "999999999999999999999",
                                }}
                            >
                                <React.Fragment>
                                    <div className="px-10 col-12 pb-3 d-flex justify-content-between align-items-center">
                                        <h1 className="mt-2 mb-0 font-weight-black h4">
                                            Replace items already in the cart?
                                        </h1>
                                    </div>
                                    <div className="px-10 mb-20">
                                        Your cart contains items from other
                                        store. Do you want to discard the
                                        selection and add items from this store?
                                    </div>
                                    <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                                        <div className="text-center mr-4 w-100">
                                            <button
                                                className="delete-button-meals p-2 px-2 w-100 position-relative"
                                                onClick={this.handlePopup}
                                                style={{
                                                    borderColor: "#fd8814",
                                                    color: "#fd8814",
                                                    // border: '1px solid #fd8814',
                                                    width: "100%",
                                                }}
                                            >
                                                NO
                                                <Ink
                                                    duration={500}
                                                    style={{ color: "#000" }}
                                                />
                                            </button>
                                        </div>

                                        <div className="text-center w-100">
                                            <button
                                                className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                                onClick={this.clearCart}
                                                style={{
                                                    width: "100%",
                                                    backgroundColor: "#ce0060",
                                                    borderColor: "#ce0060",
                                                }}
                                            >
                                                YES
                                                <Ink
                                                    duration={500}
                                                    style={{ color: "#000" }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Modal>
                    </React.Fragment>
                )}
                {float && float === true ? (
                    ""
                ) : (
                    <React.Fragment>
                        {cartProducts.length ? (
                            <div
                                className={classes.join(" ")}
                                style={{
                                    background:
                                        "rgba(217, 217, 217, )",
                                }}
                            >
                                <div className="text-dark"   >
                                    <div className="d-flex justify-content-between"  >

                                        <div className="flex-grow-1 d-flex align-items-center me-4 ms-2">
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + storeInfo?.image}
                                                placeholder={PLACE_HOLDER_IMAGE}
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            height: "10vw",
                                                            width: "10vw", borderRadius: "50%", objectFit: "cover", backgroundRepeat: "round",
                                                        }}
                                                        className="" alt="grosav"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            <div className="flex-grow-1 d-flex flex-column ms-2" >
                                                {this.state.remainingPopup == true && (
                                                    <div style={{ "fontWeight": "700", "fontSize": "15px", "position": "absolute", "top": "-40%", "left": "0%", "background": "#3e996f", "color": "white", "padding": "1px 11px", "borderRadius": "12px 12px 0px 0px" }}>
                                                        Add Rs. {parseFloat(this.state.remainingAmount).toFixed(2)} more to get Free item.
                                                    </div>
                                                )}

                                                {this.state.remainingPopup == false && this.state.free_item_added == true && (
                                                    <div style={{ "fontWeight": "700", "fontSize": "15px", "position": "absolute", "top": "-40%", "left": "0%", "background": "#3e996f", "color": "white", "padding": "1px 11px", "borderRadius": "12px 12px 0px 0px" }}>
                                                        Free Item added to cart !
                                                    </div>
                                                )}
                                                <div style={{ fontWeight: "700", fontSize: "18px", }}>
                                                    <Truncate lines={1}>
                                                        { cartProducts?.length > 0 ? cartProducts[0]?.store?.name: storeInfo?.name}
                                                    </Truncate>
                                                </div>
                                                {cartProducts?.length > 0 ?
                                                    <div style={{ fontWeight: "700", fontSize: "14px", color: '#848484' }}>
                                                        <Truncate lines={1}>
                                                            {/* You have selected {cartProducts?.length > 1 ? cartProducts?.length + " items" : cartProducts?.length + " item"}  in cart */}
                                                            {this.itemCount() + " item"} |  ₹{formatPrice(cartTotal?.totalPrice)}
                                                        </Truncate>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center w-20">
                                            <Link to={"/cart"}
                                                delay={200}>
                                                <div className="mr-2 px-2 text-white text-center d-flex align-items-center justify-content-center" style={{
                                                    background: "linear-gradient(98.81deg, #FEA24B -0.82%, #FD8814 101.53%)",
                                                    borderRadius: '9px',
                                                    width: "20vw",
                                                    height: "10vw"
                                                }}>View</div>
                                            </Link>
                                        </div>
                                    </div>
                                    {/* <Ink duration="500" /> */}
                                </div>
                            </div>
                        ) : user?.runningOrders && isRunning === true ? (
                            <div className={classes.join(" ")}
                                style={{ background: "rgba(217, 217, 217, )", }} >
                                <div className="text-dark"  >
                                    <div className="d-flex justify-content-between"  >

                                        <div className="d-flex align-items-center me-4 ms-2 flex-grow-1">
                                            <div className="position-relative">
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={IMAGE_BASE_URL + user?.runningOrders?.store?.image}
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            style={{
                                                                height: "10vw",
                                                                width: "10vw", borderRadius: "50%", objectFit: "cover", backgroundRepeat: "round",
                                                            }}
                                                            className="" alt="grosav"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                                {user?.runningOrders?.orderitems?.length > 0 &&

                                                <div className="position-absolute" style={{ top: '-5px', right: '-10px' }}>
                                                    <span className="px-1" style={{ backgroundColor: 'red', color: '#fff', fontSize: '12px', borderRadius: '50%' }}>
                                                        {user?.runningOrders?.orderitems?.length}
                                                    </span>
                                                </div>

                                                }
                                            </div>
                                            <div className="d-flex flex-column ms-3 flex-grow-1" >
                                                <div style={{ fontWeight: "700", fontSize: "18px", }}>
                                                    <Truncate lines={1}>
                                                        {user?.runningOrders?.store?.name}
                                                    </Truncate>
                                                </div>
                                                <div style={{ fontWeight: "400", fontSize: "12px", }}>
                                                    You have an ongoing order
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center w-20">
                                            <Link to={"/running-orders/normal"}
                                                delay={200}>
                                                <div className="mr-2 px-2 text-white text-center d-flex align-items-center justify-content-center" style={{
                                                    background: "linear-gradient(98.81deg, #FEA24B -0.82%, #FD8814 101.53%)",
                                                    borderRadius: '9px',
                                                    width: "20vw",
                                                    height: "10vw"
                                                }}>View</div>
                                            </Link>
                                        </div>
                                    </div>
                                    {/* <Ink duration="500" /> */}
                                </div>
                            </div>
                        ) : ("")}

                    </React.Fragment>
                )
                }
            </React.Fragment>
        );
    }
}

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};

const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
    storeInfo: state.cart.store_info,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    user: state.user.user,
    cartTotal: state.cart.total,
});

export default connect(mapStateToProps, {
    updateCart, updateStore,
    prescriptionDelete,
    clearCartItems,
    removeCoupon
})(FloatCart);
