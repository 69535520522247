import React, { Component } from "react";
import { connect } from "react-redux";
import { GiHamburger } from "react-icons/gi";
import { getAllRecommentedStores } from "../../../../redux/mobile/home/action.js";
import RecommendedCard from "./components/RecommendedCard.js";
import { BiLoader } from "react-icons/bi";

export class RecommendedForYou extends Component {
    constructor(props) {
        super(props);
        this.recommendedForYouRef = React.createRef();
        this.state = {
            data: false,
            page: 1,
            recStoreData: [], // Initialize an empty array to store concatenated data
            loading: false, // Add a loading variable
        };
    }

    componentDidMount() {
        this.fetchRecommendedStores(this.state.page);
        if (this.recommendedForYouRef.current) {

            this.recommendedForYouRef.current.addEventListener(
                "scroll",
                this.handleHorizontalScroll
            );
        } else {

        }
    }

    componentWillUnmount() {
        if (this.recommendedForYouRef.current) {

            this.recommendedForYouRef.current.removeEventListener(
                "scroll",
                this.handleHorizontalScroll
            );
        }
    }

    fetchRecommendedStores = (page) => {
        const { getAllRecommentedStores } = this.props;
        const { latitude, longitude } =
            JSON.parse(localStorage?.getItem("userSetAddress")) || {};

        this.setState({ loading: true }); // Set loading to true before starting fetch

        getAllRecommentedStores(latitude, longitude, page)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState((prevState) => ({
                        data: true,
                        page,
                        recStoreData: [
                            ...(prevState.recStoreData || []),
                            ...response.payload.data,
                        ],
                    }));
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                this.setState({ loading: false }); // Set loading to false after fetch completes
            });
    };

    handleHorizontalScroll = (event) => {
        const { scrollWidth, scrollLeft, clientWidth } = event.target;
        if (scrollWidth - scrollLeft - clientWidth < 1 && !this.state.loading) {
            const nextPage = this.state.page + 1;
            this.setState({ page: nextPage }, () => {
                this.fetchRecommendedStores(nextPage); // Fetch new stores based on the next page
            });
        }
    };

    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.forEach((rating) => {
            total += rating.rating;
        });
        total = Math.round((total / count) * 100) / 100;
        return total;
    };

    render() {
        const { recStoreData, data, loading } = this.state;

        return (
            <div className="px-3 mt-3">
                {recStoreData.length > 0 && (
                    <>
                        <GiHamburger className="mb-2" color="FF9A62" size={20} />
                        <span className="top-res-header">Recommended For You</span>
                    </>
                )}
                {recStoreData.length > 0 && (  
                <div className="d-flex flex-row align-items-center">
                    <div
                        ref={this.recommendedForYouRef}
                        id="recommendedForYou"
                        className="mt-3 hidden-scroll-bar gap-3"
                        style={{
                            display: "flex",
                            width: "100vw",
                            flexDirection: "row",
                            overflowX: "auto",
                            scrollBehavior: "smooth",
                        }}
                        onScroll={this.handleHorizontalScroll}
                    >
                        {recStoreData.map((store) => (
                            <RecommendedCard store={store} key={store._id} />
                        ))}
                        {loading && (
                            <div
                                style={{
                                    display: "flex",
                                    height: "50px",
                                    width: "50px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "50px",
                                    marginLeft: "20px",
                                    marginRight: "50px",
                                }}
                            >
                                <BiLoader
                                    className="loader"
                                    style={{
                                        height: "20px",
                                        width: "20px",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    recStore: state.home.recStore,
});

export default connect(mapStateToProps, { getAllRecommentedStores })(
    RecommendedForYou
);
