import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from '../../Elements/loading'
import {
    getVendorSingleItem,
    getAllItemCategories,
    editVendorItem,
} from "../../../redux/vendor/items/action";
import VendorBackButton from "../../Elements/VendorBackButton";
import { IMAGE_BASE_URL } from "../../../api";
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { BottomSheet } from 'react-spring-bottom-sheet'
import Modal from "react-modal";
import image from '../../../assets/images/greentick.png'
import { BiTimeFive } from "react-icons/bi";
import { Link } from "react-router-dom";

export class VendorEditItem extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id,
        categorysheet: false,
        category: '',
        categoryname: '',
        showDiscount: false,
        vendorItem: {},
        market_price: 0,
        imageName: '',
        open: false,
        file: '',
        is_veg: 0,
        res_menu_price: 0,
        
        is_none:0
    }
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.getAllItemCategories(user.data.auth_token, user.data.store[0].id);
        this.props.getVendorSingleItem(this.state.id)
            .then((response) => {
                this.setState({
                    vendorItem: response.data?.data,
                    showDiscount: true,
                    categoryname: response.data?.data?.item_category?.name,
                    category: response.data?.data?.item_category_id,
                    is_veg:response.data?.data?.is_veg
                });
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 1500);
            })
    }
    handleInput(e, name) {
        let item = this.state.vendorItem;
        item[name] = e;
        this.setState({ vendorItem: item });
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { vendorItem } = this.state;
        if (vendorItem.image?.size > 200000) {
            const alert = document.getElementById('alertdiv')
            alert.innerHTML = '<p>*The image may not be greater than 200 kilobytes.</p>'
        } else {
            let formData = new FormData()
            formData.append("id", this.state.id)
            formData.append("name", vendorItem.name)
            formData.append("image", vendorItem.image)
            formData.append("description", vendorItem.description)
            formData.append("stock", vendorItem.stock)
            formData.append("category", this.state.category)
            formData.append("selling_price", vendorItem.selling_price)
            formData.append("market_price", vendorItem.market_price)
            formData.append("token", user.data.auth_token)
            formData.append("is_veg", vendorItem.is_veg)
            formData.append("is_none", vendorItem.is_none)
            formData.append("res_menu_price", vendorItem.res_menu_price)



            this.props.editVendorItem(formData)
                .then((response) => {
                    if (response) {
                        this.setState({ vendorItem: response.data.data })
                        //// console.log(this.state.vendorItem.image)
                        this.handleModal()
                    }
                })
        }
    }
    selectCategory = (category) => {
        const id = category.id;
        const name = category.name;
        this.setState({
            category: id,
            categoryname: name,
        })
        this.closeCategoryMenu();
    }
    showDiscount = () => {
        if (this.state.showDiscount === false) {
            this.setState({ showDiscount: true })
        } else {
            this.setState({ showDiscount: false })
        }
    }
    closeCategoryMenu = () => {
        this.setState({ categorysheet: false })
    };
    openCategoryMenu = () => {
        this.setState({ categorysheet: true })
    }
    handleModal = () => {
        this.setState({ open: !this.state.open });
    };

    handleVeg = () => {
        this.setState({ is_veg: !this.state.is_veg });

    }
    render() {
        const { itemcategories } = this.props;
        const { vendorItem } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <Loading />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <VendorBackButton title="Edit Item" />
                        <div className="px-4 mt-4 pt-3">


                            <div className="px-4 pt-4">

                                <div className="coupon-heading-one">
                                    <span>Edit Item</span>
                                </div>
                                <div className="coupon-heading-two text-muted">
                                    <span>Enter Item Details</span>
                                </div>
                            </div>


                            <div className="form-coupon" style={{ paddingBottom: '25vw' }}>
                                <Form onSubmit={this.onSubmit} id="itemform">
                                    <InputGroup className="mt-4 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <label className='text-muted ps-2'>Title :</label>
                                        <FormControl
                                            type="text"
                                            placeholder="Enter item title"
                                            className="input-coupon"
                                            value={vendorItem?.name}
                                            
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "name");
                                            }}
                                        />
                                    </InputGroup>
                                    <div>
                                        <div className="coupon-input mt-3 p-2 d-flex justify-content-between ">
                                            <span className="ps-3">Current Image :</span>
                                            <div className='pe-1'>
                                                {/* {//// console.log(this.state.file)} */}
                                                <img src={this.state.file ? this.state.file : IMAGE_BASE_URL + vendorItem?.image} style={{ objectFit: 'cover', width: '75%', borderRadius: '11%' }} alt="current pic" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="coupon-input mt-3 p-2 d-flex justify-content-between ">
                                        <div style={{ wordBreak: 'break-word', width: "30px" }}>
                                            <label className='text-muted px-3'>
                                                {this.state.imageName ? (
                                                    <span>
                                                        {this.state.imageName}
                                                    </span>
                                                ) : (
                                                    <>
                                                        Item Image :
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                        <input className='upload-button-coupon' style={{ display: 'none' }} name='image' type="file" id="upload-button-image" onChange={(event) => {
                                            let file = event.target.files[0]
                                            this.setState({ file: URL.createObjectURL(file) });
                                            this.handleInput(file, "image");
                                            //// console.log(file)
                                            this.setState({ imageName: file.name });
                                        }} />
                                        <label className='upload-button-coupon px-3 py-1 ml-3' for='upload-button-image' >
                                            Upload File
                                        </label>
                                    </div>
                                    <div id="alertdiv" className='text-danger ms-2' style={{ fontSize: '12px' }}></div>
                                    <InputGroup className="mt-3 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <label className='text-muted ps-2'>Description :</label>
                                        <FormControl
                                            as="textarea" rows={3}
                                            placeholder="Item Description"
                                            className="input-coupon"
                                            value={vendorItem?.description}

                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "description");
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mt-3 coupon-input" style={{ boxSizing: 'border-box' }}>
                                        <label className="text-muted ps-2">Is Veg :</label>
                                        <div className="d-flex flex-column">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="is_veg"
                                                    id="veg"
                                                    value="veg"
                                                    checked={vendorItem?.is_veg == true}
                                                    onChange={() => this.handleInput(true, "is_veg")}
                                                />
                                                <label className="form-check-label" htmlFor="veg">
                                                    Veg
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="is_veg"
                                                    id="non-veg"
                                                    value="non-veg"
                                                    checked={vendorItem?.is_veg == false}
                                                    onChange={() => this.handleInput(false, "is_veg")}
                                                />
                                                <label className="form-check-label" htmlFor="non-veg">
                                                    Non-Veg
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="is_veg"
                                                    id="is_none"
                                                    value="is_none"
                                                    checked={vendorItem?.is_none == true}
                                                    onChange={() => this.handleInput(true, "is_none")}
                                                />
                                                <label className="form-check-label" htmlFor="is_none">
                                                Is None (If item not veg or non veg. Turn this on)
                                                </label>
                                            </div>
                                        </div>
                                    </InputGroup>
                                    <InputGroup
                                        className="mt-3 coupon-input p-2 d-flex justify-content-between"
                                        style={{ boxSizing: 'border-box' }}
                                        onClick={() => this.openCategoryMenu()}
                                    >
                                        <div>
                                            <input type="hidden" value={this.state.category}  />
                                            <label for='category-type' className='text-muted px-3 py-1'>
                                                {this.state.categoryname ? (
                                                    <>
                                                        {this.state.categoryname}
                                                    </>
                                                ) : (
                                                    <>
                                                        select an option
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                        <div>
                                            <span className='px-1 py-2 ml-3' id='category-type'  ><HiOutlineChevronRight style={{ color: '#DA6317' }} /></span>
                                        </div>
                                    </InputGroup>
                                    <BottomSheet
                                        open={this.state.categorysheet}
                                        onDismiss={() => this.closeCategoryMenu()}
                                        snapPoints={({ maxHeight }) => 0.36 * maxHeight}
                                    >
                                        <div className='px-3 pb-5' >
                                            <div className="mt-1 mb-2 " style={{
                                                fontWeight: '600',
                                                fontSize: '16px'
                                            }}>
                                                Select Item Category
                                            </div>
                                            <div className='px-3 pb-5' >
                                                <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                                    {itemcategories?.data?.length > 0 ? (
                                                        <>
                                                            <div className="d-grid gap-2">
                                                                {itemcategories?.data?.map((category) =>
                                                                    <>
                                                                        <button className="offer-sheet-btn" onClick={() => this.selectCategory(category)} >
                                                                            {category.name}
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>
                                    <div>
                                        <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                            <label className='text-muted ps-2'>Stock :</label>
                                            <FormControl
                                                type="number" min={0}
                                                placeholder="Stock"
                                                className="input-coupon"
                                                value={vendorItem?.stock}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.handleInput(value, "stock");
                                                }}
                                                
                                            />
                                        </InputGroup>
                                    </div>
                                    {/* <div className='d-flex justify-content-between'> */}
                                        {/* <div>
                                            <InputGroup className="mt-3 coupon-input" style={{ boxSizing: 'border-box' }} >
                                                <label className='text-muted ps-2'>Selling Price :</label>
                                                <FormControl
                                                    type="number"
                                                    min={0}
                                                    placeholder="Item Price"
                                                    className="input-coupon"
                                                    value={vendorItem?.selling_price}
                                                    required
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        this.handleInput(value, "selling_price");
                                                    }}
                                                />
                                            </InputGroup>
                                        </div> */}
                                        {/* {//// console.log(parseInt(vendorItem?.market_price), 'price')} */}
                                        {/* {parseInt(vendorItem?.market_price) <= 0 ? (
                                            <div className='d-flex flex-column'>
                                                <button className='add-on-btn mt-3 py-2 ms-2 w-100' type="button" onClick={() => this.showDiscount()}><HiOutlinePlusSmall /></button>
                                                <small className='ms-2 text-muted'>*Discount</small>
                                            </div>
                                        ) : null} */}
                                    {/* </div> */}
                                    {/* <div >
                                        <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                            <label className='text-muted ps-2'>Market Price :</label>
                                            <FormControl
                                                type="number" min={0}
                                                placeholder="Market Price"
                                                className="input-coupon"
                                                value={vendorItem?.market_price}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.handleInput(value, "market_price");
                                                }}
                                            />
                                        </InputGroup>
                                    </div> */}
                                    <div >
                                        <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                            <label className='text-muted ps-2'>Restaurant Menu Price :</label>
                                            <FormControl
                                                type="number" min={0}
                                                placeholder="Restaurant Menu Price"
                                                className="input-coupon"
                                                value={vendorItem?.res_menu_price}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.handleInput(value, "res_menu_price");
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div style={{ height:"150px" }}></div>
                                    <div className="position-fixed w-100 me-3 px-4" style={{ bottom: '0px', left: '0px' }}>
                                    <Link to={'/vendor-item-schedule/' + this.state.id}>

                                        <button type='button' className='button-coupon p-3' >
                                           Click to Manage Item Schedule
                                           <BiTimeFive className='pt-1 ml-3' style={{ fontSize: "1.8em", color: "#fff", }} />
                                        </button>
                                    </Link>
                                        <button type='submit' className='button-coupon p-3' >
                                            Update
                                        </button>
                                    </div>
                                </Form>
                            </div>

                        </div>
                        <Modal
                            isOpen={this.state.open}
                            ariaHideApp={false}
                            onRequestClose={() => this.handleModal()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div
                                className="container"
                                style={{
                                    borderRadius: "5px",
                                    height: "210px",
                                    zIndex: "9999",
                                }}
                            >
                                <React.Fragment>
                                    <div className="mb-20 d-flex justify-content-center align-items-center">
                                        <img src={image} style={{ objectFit: 'contain', height: '110px' }} alt="success" />
                                    </div>
                                    <div className="col-12 pb-3 d-flex flex-column align-items-center justify-content-center">
                                        <h1 className="pt-3 mb-0 font-weight-black h4">
                                            Item
                                        </h1>
                                        <h1 className="pt-1 mb-0 font-weight-black h4">
                                            Updated Successfully
                                        </h1>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Modal>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    singleitem: state.items.singleitem,
    itemcategories: state.items.itemcategories,
});

export default connect(mapStateToProps, {
    getVendorSingleItem,
    getAllItemCategories,
    editVendorItem,
})(VendorEditItem);
