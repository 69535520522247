// src/store.js

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { baseApi } from '../configs/baseApi'; // Assuming this is your RTK Query API slice

// Function to load persisted state from localStorage
const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.warn('Failed to load state from localStorage:', err);
    return undefined;
  }
};

// Function to save state to localStorage
const saveState = (state) => {
  try {
    const persist = {
      vendorOrder: state.vendorOrder,
      user: state.user,
      cart: state.cart,
      checkout: state.checkout,
      mobileitems: state.mobileitems,
      medicine: state.medicine,
      mobileCoupon: state.mobileCoupon,
      mobileOrder: state.mobileOrder,
      total: state.total,
      vendor: state.vendor,
      items: state.items,
      itemcategories: state.itemcategories,
      addoncategories: state.addoncategories,
      order: state.order,
      store: state.store,
      coupons: state.coupons,
      payouts: state.payouts,
      display_order: state.display_order,
      cards: state.cards,
      subscription: state.subscription,
      home: state.home,
      mobilestorecategory: state.mobilestorecategory,
      notification: state.notification,
      singleStore: state.singleStore,
      anyStore: state.anystore,
      // Add other slices you want to persist
    };
    const serializedState = JSON.stringify(persist);
    window.localStorage.setItem('state', serializedState);
  } catch (err) {
    console.warn('Failed to save state to localStorage:', err);
  }
};

// Initialize persisted state
const persistedState = loadState();

// Configure the store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Adjust based on your needs
      immutableCheck: false,
    }).concat(baseApi.middleware), // Add RTK Query middleware
  preloadedState: persistedState,
  devTools: true, // Enable Redux DevTools in development
});

// Subscribe to store updates to persist state
store.subscribe(() => {
  const state = store.getState();
  saveState(state);

  // If needed, post the state to React Native or WebKit
  if (window.ReactNativeWebView) {
    const response = {
      type: 'state',
      data: state, // Or adjust based on what you need to send
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(response));
  }
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.nativeApp
  ) {
    window.webkit.messageHandlers.nativeApp.postMessage(
      JSON.stringify({
        type: 'state',
        data: state, // Or adjust based on what you need to send
      })
    );
  }
});

export default store;
