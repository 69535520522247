import { baseApi } from "../../../configs/baseApi"


export const homeApi = baseApi.enhanceEndpoints({
    addTagTypes: ["auth"],
}).injectEndpoints({
    endpoints: (builder) => ({
        getAllStore: builder.query({
            query: (data) => ({
                url: "/get-paginated-resturants",
                method: "POST",
                data,
            }),

            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, { arg }) => {
                if (arg.resetPagination) {
                    return newItems;
                }
                const oldData = currentCache.data || [];
                const newData = newItems.data || [];

                if (currentCache.page !== newItems.page && newItems.page !== 1) {
                    // Deduplicate the items before merging
                    const mergedData = [
                        ...oldData,
                        ...newData.filter(
                            (item) =>
                                !oldData.some((existingItem) => existingItem.id === item.id)
                        ), // Replace `id` with your unique key
                    ];

                    currentCache = {
                        ...newItems,

                        data: mergedData,

                    };
                } else {
                    currentCache = newItems;
                }

                return currentCache;
            },

            forceRefetch: ({ currentArg, previousArg }) => {
                if (!currentArg || !previousArg) return false;
                return Object.keys(currentArg).some(
                    (key) => currentArg[key] !== previousArg[key]
                );
            },
        }),


        getNearStores: builder.query({
            query: (data) => ({
                url: '/get-all-paginated-near-stores',
                method: "POST",
                data,
            }),

            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, { arg }) => {
                if (arg?.resetPagination) {
                    return newItems;
                }
                const oldData = currentCache.data || [];
                const newData = newItems.data || [];

                if (currentCache?.pagination?.current_page !== newItems?.pagination?.current_page && newItems?.pagination?.current_page !== 1) {
                    // Deduplicate the items before merging
                    const mergedData = [
                        ...oldData,
                        ...newData.filter(
                            (item) =>
                                !oldData.some((existingItem) => existingItem.id === item.id)
                        ), // Replace `id` with your unique key
                    ];

                    currentCache = {
                        ...newItems,

                        data: mergedData,

                    };
                } else {
                    currentCache = newItems;
                }

                return currentCache;
            },

            forceRefetch: ({ currentArg, previousArg }) => {
                if (!currentArg || !previousArg) return false;
                return Object.keys(currentArg).some(
                    (key) => currentArg[key] !== previousArg[key]
                );
            },
        }),

        getTopStores: builder.query({
            query: (data) => ({
                url: '/get-all-paginated-top-stores',
                method: "POST",
                data,
            }),

            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, { arg }) => {
                if (arg?.resetPagination) {
                    return newItems;
                }
                const oldData = currentCache.data || [];
                const newData = newItems.data || [];

                if (currentCache?.pagination?.current_page !== newItems?.pagination?.current_page && newItems?.pagination?.current_page !== 1) {
                    // Deduplicate the items before merging
                    const mergedData = [
                        ...oldData,
                        ...newData.filter(
                            (item) =>
                                !oldData.some((existingItem) => existingItem.id === item.id)
                        ), // Replace `id` with your unique key
                    ];

                    currentCache = {
                        ...newItems,

                        data: mergedData,

                    };
                } else {
                    currentCache = newItems;
                }

                return currentCache;
            },

            forceRefetch: ({ currentArg, previousArg }) => {
                if (!currentArg || !previousArg) return false;
                return Object.keys(currentArg).some(
                    (key) => currentArg[key] !== previousArg[key]
                );
            },
        }),

        getAllMedicalStores: builder.query({
            query: (data) => ({
                url: '/get-all-paginated-medicine-data',
                method: "POST",
                data,
            }),
            
            

            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, { arg }) => {
                if (arg.resetPagination) {
                    return newItems;
                }
            
                const oldData = currentCache.data || [];
                const newData = newItems.data || [];   

                if (currentCache.page !== newItems.page && newItems.page !== 1) {
                    // Deduplicate the items before merging
                    const mergedData = [
                        ...oldData,
                        ...newData.filter(
                            (item) =>
                                !oldData.some((existingItem) => existingItem.id === item.id)
                        ), // Replace `id` with your unique key
                    ];

                    currentCache = {
                        ...newItems,

                        data: mergedData,

                    };
                } else {
                    currentCache = newItems;
                }

                return currentCache;
            },

            forceRefetch: ({ currentArg, previousArg }) => {
                if (!currentArg || !previousArg) return false;
                return Object.keys(currentArg).some(
                    (key) => currentArg[key] !== previousArg[key]
                );
            },
        }),
    })
})

export const {
    useLazyGetAllStoreQuery,
    useLazyGetNearStoresQuery,
    useLazyGetTopStoresQuery,
    useLazyGetAllMedicalStoresQuery
} = homeApi;
