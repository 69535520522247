import React, { Component } from "react";
import { connect } from "react-redux";
import { CgProfile } from "react-icons/cg";
import { IoMdHome } from "react-icons/io";
import { Link } from "react-router-dom";
import { TbDiscount2 } from "react-icons/tb";
import { BiSearch } from "react-icons/bi";
import Truncate from "react-truncate";
import HomeCategory from "./HomeCategory";
import HomeEssential from "./HomeEssential";
import TopOffersForYou from "./TopOffersForYou";
import CoupenForYou from "./CoupenForYou";
import DelivaryBike from "../../../assets/images/Deliverybike.png";
import Door from "../../../assets/images/door.png";
import building from "../../../assets/images/building.png";
import tag from "../../../assets/images/hometag.png";
import Footer from "../Footer";
import ContentLoader from "react-content-loader";
import { MdHomeFilled, MdLocationOn } from "react-icons/md";
import { updateUserReduxInfo } from "../../../redux/mobile/user/action";
import { getSettings } from "../../../redux/mobile/home/action";
import Fade from "react-reveal/Flip";
import Bounce from "react-reveal/Bounce";
import animationData from "../../../assets/lottie/location-load.json";
import Lottie from "react-lottie";
import { HiLocationMarker } from "react-icons/hi";
import { add } from "lodash";
import ReactPlayer from "react-player";

export class Home extends Component {
    state = {
        loading: false,
        location_loading: false,
        address: [],
    };

    componentDidMount() {
        this.props.getSettings();
        // sessionStorage.setItem("is_location", "true")
        localStorage.removeItem("prevStoreIdForScroll");

        const { user } = this.props;
        let is_location = sessionStorage.getItem("is_location");
        if (is_location) {
            this.setState({
                address: JSON.parse(localStorage.getItem("userSetAddress")),
            });
            if (user?.success) {
                this.props.updateUserReduxInfo(user?.data?.auth_token);
            }
        } else {
            this.props.history.push("/set-location");
        }
        let updated = localStorage.getItem("is_location");
        if (updated) {
            this.setState({ location_loading: true });
            setTimeout(() => {
                this.setState({ location_loading: false });
                localStorage.removeItem("is_location");
            }, 3000);
        }
    }
    getAddresCity = () => {
        let addresses = localStorage.getItem("address")
            ? localStorage.getItem("address").split(",")
            : "No Address";
        return addresses[0];
    };
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        const { user } = this.props;
        const { address, loading, location_loading } = this.state;

        if (!user || user.length === 0 || !user?.success) {
            // Redirect to login
            this.props.history.push("/signup");
        } else {
            // Continue with the current logic
        }
        
        const video_url = localStorage.getItem("home_page");


        return (
            <React.Fragment>
                {loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="10"
                                y="8"
                                rx="10"
                                ry="20"
                                width="22"
                                height="22"
                            />
                            <rect
                                x="40"
                                y="13"
                                rx="0"
                                ry="0"
                                width="50"
                                height="12"
                            />
                            <rect
                                x="10"
                                y="33"
                                rx="0"
                                ry="0"
                                width="100"
                                height="6"
                            />
                            <rect
                                x="185"
                                y="15"
                                rx="0"
                                ry="0"
                                width="60"
                                height="18"
                            />
                            <rect
                                x="250"
                                y="12"
                                rx="8"
                                ry="8"
                                width="22"
                                height="22"
                            />
                            <rect
                                x="12"
                                y="50"
                                rx="10"
                                ry="10"
                                width="254"
                                height="47"
                            />
                            <rect
                                x="12"
                                y="115"
                                rx="10"
                                ry="10"
                                width="180"
                                height="24"
                            />
                            <rect
                                x="18"
                                y="150"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="105"
                                y="150"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="190"
                                y="150"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="18"
                                y="250"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="105"
                                y="250"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="190"
                                y="250"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="12"
                                y="350"
                                rx="10"
                                ry="10"
                                width="100"
                                height="20"
                            />
                            <rect
                                x="14"
                                y="390"
                                rx="10"
                                ry="10"
                                width="55"
                                height="69"
                            />
                            <rect
                                x="80"
                                y="390"
                                rx="10"
                                ry="10"
                                width="55"
                                height="69"
                            />
                            <rect
                                x="144"
                                y="390"
                                rx="10"
                                ry="10"
                                width="55"
                                height="69"
                            />
                            <rect
                                x="210"
                                y="390"
                                rx="10"
                                ry="10"
                                width="55"
                                height="69"
                            />
                            <rect
                                x="12"
                                y="480"
                                rx="10"
                                ry="10"
                                width="150"
                                height="22"
                            />
                            <rect
                                x="15"
                                y="510"
                                rx="10"
                                ry="10"
                                width="197"
                                height="70"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Bounce collapse top when={location_loading}>
                            <div
                                className="home-location-image position-relative"
                                style={
                                    !location_loading
                                        ? { display: "none" }
                                        : { height: window.innerHeight }
                                }
                            >
                                <div
                                    className="d-flex flex-column align-items-center justify-content-between px-5 position-absolute"
                                    style={{ paddingTop: "25vw" }}
                                >
                                    <div className="px-3">
                                        <Lottie
                                            options={defaultOptions}
                                            height={250}
                                            width={250}
                                        />
                                        <div
                                            className="text-center fw-bolder fs-6 text-muted"
                                            style={{ fontWeight: "500" }}
                                        >
                                            DELIVERING TO
                                        </div>

                                        <div
                                            className="text-center "
                                            style={{
                                                fontWeight: "800",
                                                fontSize: "1.8em",
                                            }}
                                        >
                                            <MdLocationOn
                                                color="#ffa744"
                                                size={25}
                                            />{" "}
                                            {this.getAddresCity()}
                                        </div>
                                        <div
                                            className="text-center"
                                            style={{ fontWeight: "500" }}
                                        >
                                            {localStorage
                                                .getItem("address")
                                                ?.replace('"', "")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Bounce>
                        <div
                            style={
                                localStorage.getItem("is_location") ||
                                location_loading
                                    ? { display: "none" }
                                    : {
                                          overflowX: "hidden",
                                          height: window.innerHeight,
                                      }
                            }
                        >
                            <div className="mobile-home-bg-img py-3 d-flex flex-column px-3 justify-content-between ">
                                <div className="d-flex flex-row justify-content-between w-100">
                                    <div className="d-flex flex-row align-items-center">
                                        <MdHomeFilled
                                            style={{ fontSize: "2em" }}
                                        />
                                        <span className="mobile-span-home ps-2">
                                            Home
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                        {user && user.success ? (
                                            <Link
                                                to="/my-coupon"
                                                className="text-dark"
                                            >
                                                <div>
                                                    <TbDiscount2
                                                        style={{
                                                            fontSize: "30px",
                                                        }}
                                                    />
                                                    <span className="mobile-home-span-3 ps-2 pe-2">
                                                        Offer
                                                    </span>
                                                </div>
                                            </Link>
                                        ) : (
                                            <Link
                                                to="/signup"
                                                className="text-dark"
                                            >
                                                <div>
                                                    <TbDiscount2
                                                        style={{
                                                            fontSize: "30px",
                                                        }}
                                                    />
                                                    <span className="mobile-home-span-3 ps-2 pe-2">
                                                        Offer
                                                    </span>
                                                </div>
                                            </Link>
                                        )}
                                        {user &&
                                        user.success &&
                                        user?.data?.auth_token ? (
                                            <Link
                                                to="/my-profile"
                                                className="text-dark"
                                            >
                                                <div>
                                                    <CgProfile
                                                        style={{
                                                            fontSize: "30px",
                                                        }}
                                                    />
                                                </div>
                                            </Link>
                                        ) : (
                                            <Link
                                                to="/signup"
                                                className="text-dark"
                                            >
                                                <div>
                                                    <CgProfile
                                                        style={{
                                                            fontSize: "30px",
                                                        }}
                                                    />
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className="w-75 mobile-home-span-2 mt-2 text-black">
                                    <Link
                                        to={"/set-location"}
                                        style={{ color: "#000" }}
                                    >
                                        <div>
                                            <Truncate lines={1}>
                                                {address?.house
                                                    ? address?.house + ","
                                                    : ""}
                                                {" " + address?.address}
                                            </Truncate>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="px-3">
                                <Link to="/explore-menu">
                                    <div className="d-flex flex-row align-items-center mobile-searchBar py-2 px-2">
                                        <input
                                            type="text"
                                            className="mobile-inventory-search w-100"
                                            placeholder="What do you want to order ?"
                                        />
                                        <span className="">
                                            <BiSearch
                                                set="curved"
                                                style={{
                                                    fontSize: "1.7em",
                                                    color: "#FEA24B",
                                                }}
                                            />
                                        </span>
                                    </div>
                                </Link>
                            </div>
                            <HomeEssential />
                            <HomeCategory />
                            <TopOffersForYou />
                            <div className="px-3 py-3 pb-5 mobile-btm-brdr">
                                
                                <ReactPlayer
                                    url={video_url}
                                    playing={false}
                                    controls={true}
                                    width="100%"
                                    height="200px" // Adjust height as needed
                                    style={{
                                        borderRadius: "10px",
                                        overflow: "hidden",
                                    }} // Add border radius
                                />
                            </div>

                            {/* <CoupenForYou /> */}
                            <div
                                className="ms-3 d-flex justify-content-between mt-2 w-100"
                                style={{ paddingBottom: "38vw" }}
                            >
                                <div className="">
                                    <div className="d-flex  mt-5">
                                        <div className="">
                                            <img src={Door} alt="" />
                                        </div>
                                        <div className="mobile-end-cnt ps-2">
                                            Get things delivered to your
                                            doorstep instantly
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3">
                                        <div className="">
                                            <img src={building} alt="" />
                                        </div>
                                        <div className="mobile-end-cnt ps-2">
                                            Deliver from anywhere in the city
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3">
                                        <div className="">
                                            <img src={tag} alt="" />
                                        </div>
                                        <div className="mobile-end-cnt ps-2">
                                            No minimum order value on your
                                            purchase
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 ">
                                    <img src={DelivaryBike} alt="" />
                                </div>
                            </div>
                        </div>
                        {!location_loading && <Footer active_store={true} />}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, {
    updateUserReduxInfo,
    getSettings,
})(Home);
