import React, { Component } from "react";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import VendorBackButton from "../../Elements/VendorBackButton";
import Loading from "../../Elements/loading";
import {
    exportDateWise,
    getExportedDateWise,
} from "../../../redux/vendor/user/action";
import { Form, InputGroup } from "react-bootstrap";
import moment from "moment";
import Nosales from "../../../assets/images/noSales.png";
import { WEBSITE_URL } from "../../../api";
import { formatPrice } from "../../Helpers/formatPrice";
import { Link } from "react-router-dom";

class VendorDateWiseSale extends Component {
    state = {
        loading: false,
        sales: false,
        no_sales: true,
        start_date: "",
        end_date: "",
        orders: "",
        total_value: "",
        total_payout_value: "",
        cancelledOrdersCount: "",
    };
    startDateChange = (e) => {
        this.setState({ start_date: e });
        // //// console.log(this.state.start_date);
    };
    endDateChange = (e) => {
        this.setState({ end_date: e });
        // //// console.log(this.state.end_date);
    };
    dateWiseSale = (e) => {
        const { user } = this.props;
        // const { start_date, end_date } = this.state;
        e.preventDefault();
        this.setState({ loading: true, no_sales: false });
        this.props
            .getExportedDateWise(
                user && user.data && user.data.auth_token,
                this.state.start_date,
                this.state.end_date
            )
            .then((response) => {
                // //// console.log(response,'response');
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        orders: response.payload.orders,
                        total_value: response.payload.totalValue,
                        total_payout_value: response.payload.totalPayoutValue,
                        cancelledOrdersCount:
                            response.payload.cancelledOrdersCount,
                        sales: true,
                    });

                    // if (response && response.payload && response.payload.orders && response.payload.orders.length > 0) {
                    //     this.setState({ sales: true, })
                    // } else {
                    //     this.setState({ sales: false, })
                    // }

                    // //// console.log(this.state.orders, this.state.start_date,'dates');
                } else {
                    //// console.log('API Error');
                }
            });
    };
    dataWiseReport = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { start_date } = this.state;
        const { end_date } = this.state;
        window.location =
            WEBSITE_URL +
            "/date-wise-order?token=" +
            user.data.auth_token +
            "&start_date=" +
            start_date +
            "&end_date=" +
            end_date;
    };

    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    render() {
        const {
            orders,
            total_value,
            total_payout_value,
            cancelledOrdersCount,
        } = this.state;
        const today = moment().format("YYYY-MM-DD");
        // //// console.log('todAY',today);
        // today.setDate(today.getDate() + 1);
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <div>
                                <VendorBackButton title="Date Wise Sales" />
                            </div>
                            <div
                                className="px-4"
                                style={{ paddingTop: "20vw" }}
                            >
                                <Form onSubmit={(e) => this.dateWiseSale(e)}>
                                    <div
                                        className="mt-4"
                                        style={{
                                            fontSize: "1.2em",
                                            fontWeight: "650",
                                        }}
                                    >
                                        Start Date
                                    </div>
                                    <InputGroup
                                        hasValidation
                                        className="b-r-10 input-register-user w-100 mt-2"
                                    >
                                        <input
                                            type="date"
                                            required
                                            id="start_date"
                                            max={`${today}`}
                                            placeholder="Select Start Date"
                                            value={
                                                this.state.start_date &&
                                                this.state.start_date
                                            }
                                            className="input-register-details start_date w-100"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.startDateChange(value);
                                            }}
                                        />
                                    </InputGroup>
                                    <div
                                        className="mt-4"
                                        style={{
                                            fontSize: "1.2em",
                                            fontWeight: "650",
                                        }}
                                    >
                                        End Date
                                    </div>
                                    <InputGroup
                                        hasValidation
                                        className="b-r-10 input-register-user w-100 mt-2"
                                    >
                                        <input
                                            type="date"
                                            required
                                            id="end_date"
                                            placeholder="Select End Date"
                                            min={`${moment(
                                                this.state.start_date
                                            ).format("YYYY-MM-DD")}`}
                                            max={`${today}`}
                                            value={
                                                this.state.end_date &&
                                                this.state.end_date
                                            }
                                            className="input-register-details w-100 end_date"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.endDateChange(value);
                                            }}
                                        />
                                    </InputGroup>
                                    <div className="pt-5 text-end">
                                        <button
                                            type="submit"
                                            className="order-btn py-3 w-100"
                                            // onClick={this.showDetails}
                                            style={{ fontSize: "15px" }}
                                        >
                                            Show Sales
                                        </button>
                                    </div>
                                </Form>
                                {/* {this.state.no_sales ?
                                    <div className="d-flex aligh-items-center justify-content-center p-5">
                                        <span className="select-date-text">
                                            Select Start & End Date
                                        </span>
                                    </div>
                                    : ""
                                } */}
                                <div>
                                    <div>
                                        {/* {//// console.log(orders,'oeder')} */}
                                        {orders?.length > 0 ? (
                                            <div>
                                                <div className="d-flex flex-row justify-content-between px-4 mt-2">
                                                    <div className="d-flex flex-column px-1">
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "700",
                                                                fontSize:
                                                                    "18px",
                                                            }}
                                                            className="pt-2"
                                                        >
                                                            Orders
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "500",
                                                                fontSize:
                                                                    "14px",
                                                                color: "#898791",
                                                            }}
                                                        >
                                                            Total Order Value :
                                                            Rs{" "}
                                                            {formatPrice(
                                                                total_value
                                                            )}
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "500",
                                                                fontSize:
                                                                    "14px",
                                                                color: "#898791",
                                                            }}
                                                        >
                                                            Total Payout Amount
                                                            :{" "}
                                                            <strong>
                                                                Rs{" "}
                                                                {formatPrice(
                                                                    total_payout_value
                                                                )}
                                                            </strong>
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "500",
                                                                fontSize:
                                                                    "14px",
                                                                color: "#898791",
                                                            }}
                                                        >
                                                            Order Count:{" "}
                                                            <strong>
                                                                {orders?.length}
                                                            </strong>
                                                        </span>

                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "500",
                                                                fontSize:
                                                                    "14px",
                                                                color: "#898791",
                                                            }}
                                                        >
                                                            Cancel Order Count:{" "}
                                                            <strong>
                                                                {
                                                                    cancelledOrdersCount
                                                                }
                                                            </strong>
                                                        </span>
                                                    </div>
                                                    <div className="mt-2 ">
                                                        <button
                                                            type="submit"
                                                            className="export-report-btn py-3"
                                                            onClick={
                                                                this
                                                                    .dataWiseReport
                                                            }
                                                        >
                                                            Export Orders
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    className="mx-4 py-4 mt-3"
                                                    style={{
                                                        background:
                                                            "rgba(217,217,217,0.2)",
                                                        borderRadius: "10px",
                                                    }}
                                                >
                                                    {orders &&
                                                        orders.map(
                                                            (data, index) => (
                                                                <div className="total-order mx-3 d-flex align-items-center justify-content-between mt-3">
                                                                    <Link
                                                                        to={`/vendor-single-order/${
                                                                            data.id &&
                                                                            data.id
                                                                        }/${
                                                                            data.type &&
                                                                            data.type
                                                                        }`}
                                                                        style={{
                                                                            textDecoration:
                                                                                "none",
                                                                            color: "inherit",
                                                                        }} // Keeps the default text styles intact
                                                                    >
                                                                        <div className="d-flex flex-column mx-3 my-3">
                                                                            <span className="orders">
                                                                                #
                                                                                {data.unique_order_id &&
                                                                                    data.unique_order_id}
                                                                            </span>
                                                                            <span className="order-date">
                                                                                {data.order_accepted_at &&
                                                                                    moment(
                                                                                        data.order_accepted_at
                                                                                    ).format(
                                                                                        "LL"
                                                                                    )}
                                                                            </span>
                                                                        </div>
                                                                        <div className="d-flex flex-column mx-3 my-3">
                                                                            <span className="order-value">
                                                                                {/* Order value */}
                                                                            </span>
                                                                            {/* <span className="order-price" style={{ fontSize: '14px' }}>
                                                                    Rs.{data?.sub_total && data?.sub_total}
                                                                </span> */}
                                                                            <span
                                                                                className="order-price"
                                                                                style={{
                                                                                    fontSize:
                                                                                        "14px",
                                                                                }}
                                                                            >
                                                                                Rs.{" "}
                                                                                {data?.store_total ??
                                                                                    0}
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex flex-column align-items-center justify-content-center mt-4 px-3">
                                                <img
                                                    src={Nosales}
                                                    style={{
                                                        width: "80%",
                                                        objectFit: "cover",
                                                    }}
                                                    alt="no_sale"
                                                />
                                                <span
                                                    className="fw-bolder"
                                                    style={{
                                                        fontSize: "1.1em",
                                                    }}
                                                >
                                                    No Sales Found
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    order: state.vendor.order,
    user: state.vendor.store_user,
});

export default connect(mapStateToProps, {
    exportDateWise,
    getExportedDateWise,
})(VendorDateWiseSale);
