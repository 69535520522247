import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import Items from "./Items";
import Meals from "./Meals";
import { Link } from "react-router-dom";
import Tip from "./Tip";
import Bill from "./Bill";
import { ImUpload2 } from "react-icons/im";
import { BiErrorCircle } from "react-icons/bi";
import {
    getCartProducts,
    updateCart,
    calcualteStoreMaxDistance,
    calculateStoreChargeTax,
    addPrescription,
    prescriptionDelete,
    updateStore,
} from "../../../redux/mobile/cart/action";
import ContentLoader from "react-content-loader";
import CouponBlock from "./ApplyCoupon/CouponBlock";
import { applyCoupon } from "../../../redux/mobile/mobileCoupon/action";
import { getStoreInfoAndOperationalStatus } from "../../../redux/mobile/item/action";
import { calculateDistanceGoogle } from "../../Helpers/calculateDistanceGoogle";
import Ink from "react-ink";
import ImageList from "./ImageList";
import { TiLocationArrow } from "react-icons/ti";
import { AiFillDelete } from "react-icons/ai";
import Modal from "react-modal";
import FloatCart from "./FloatCart";
// import { IMAGE_BASE_URL, WEBSITE_BASE_URL } from '../../../api'
// import ProgressiveImage from 'react-progressive-image'
import image from "../../../assets/images/nocartitems.png";
import { set } from "lodash";
import Lottie from "react-lottie";
import animationData from '../../../assets/lottie/130285-loading.json';
import Shake from "react-reveal/Shake";
import { formatPrice } from "../../Helpers/formatPrice";
import { TbDiscountCheckFilled, TbLocationOff } from "react-icons/tb";
import { getSettings } from '../../../redux/mobile/home/action';
import Confetti from "react-confetti";
import { BsScooter } from "react-icons/bs";
import Axios from "axios";
import { GET_LATEST_STORE_INFO_URL } from "../../../api";
import Savings from "./SavingsComponent";

export class Cart extends Component {
    state = {
        loading: false,
        product: [],
        newStore: false,
        is_all_items_available: false,
        is_operational: true,
        is_medicine: false,
        distance: 0,
        max_distance: "",
        store: "",
        tip: "",
        custom_tip: "",
        storeCharges: "",
        tax: "",
        show_auto_apply_modal: false,
        coupon_error: false,
        slabComplete: false,
        is_scheduled: false,
        store_close: true,
        address: [],
        files: [],
        images: [],
        noPres: false,
        open: false,
        image_id: "",
        floatCart: true,
        imageAdd: false,
        nearstores: [],
        is_deliverable: false,
        no_images: false,
        instructions: "",
        savings: 0
    };
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    executeScroll = () => this.myRef.current.scrollIntoView()

    changeAddress = (e) => {
        e.preventDefault();
        localStorage.setItem("LocationFrom", "CART");
        this.props.history.push("/my-address");
    };
    componentDidMount() {
        const { user, cartProducts, store_info } = this.props;
        
        let comment = localStorage.getItem("orderComment");

        if (comment) {
            this.setState({ instructions: comment })
        }
        this.props.getSettings();

        // updateStore()

        Axios.post(GET_LATEST_STORE_INFO_URL, { id: store_info.id, }).then((response) => {
            //// console.log(response);
            this.props.updateStore(response?.data)
            // this.setState({ pause_anystore: response?.data?.pause_anystore })
        });

        this.setState({ loading: true });
        window.addEventListener("scroll", this.handleScroll);
        let tip = localStorage.getItem("tip")
        this.setState({ tip: tip })
        this.setState({ address: JSON.parse(localStorage.getItem("userSetAddress")), });

        if (cartProducts && cartProducts?.length > 0) {

            this.props.getCartProducts(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude, cartProducts[0]?.store.id).then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false, nearstores: response.payload.nearstores ,
                        is_deliverable:response.payload.is_deliverable});
                }
            });
        }

        if (user && user.success) {
            let formData = new FormData();
            formData.append("token", user.data.auth_token);
            formData.append("type", "CARTORDER");
            this.setState({ imageAdd: true })
            this.props.addPrescription(formData).then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ imageAdd: false });
                }
            });
        }

        this.props.calcualteStoreMaxDistance(user.success && user.data.id, cartProducts, JSON.parse(localStorage.getItem("userSetAddress")))
            .then((response) => {
                if (response && response.payload) {
                    this.setState({
                        max_distance: response.payload.distance,
                        store: response.payload.store_id,
                    });

                    localStorage.setItem("max_distance", response.payload.distance);
                }
            });
        this.props.calculateStoreChargeTax(cartProducts);
    }

    addTip = (e) => {
        this.setState({ tip: e });
        localStorage.setItem("tip", e)
    };

    calculatetip = () => {
        if (this.state.tip) {
            if (this.state.tip === "other") {
                if (this.state.custom_tip) {
                    return this.state.custom_tip;
                } else {
                    return 0;
                }
            } else {
                return this.state.tip;
            }
        } else {
            return 0;
        }
    };

    componentWillReceiveProps(nextProps) {
        let store_id = parseFloat(localStorage.getItem("store_id"));

        if (
            nextProps.cartTotal.totalPrice !==
            this.props.cartTotal.totalPrice &&
            this.props.coupon &&
            this.props.coupon.code
        ) {
            this.props
                .applyCoupon(
                    nextProps.user.data.auth_token,
                    this.props.coupon.code,
                    nextProps.cartProducts,
                    nextProps.cartTotal.totalPrice,
                    store_id,
                )
                .then((response) => {
                    if (response && response[0].payload.success == false) {
                        this.setState({ coupon_error: true });
                        // ////// console.log(response);
                    }
                });
        }
        if (
            nextProps.current_slab &&
            this.props.current_slab !== nextProps.current_slab
        ) {
            if (
                parseInt(
                    this.props.current_slab
                        ? this.props.current_slab.bucket_price_to
                        : 0
                ) < parseInt(nextProps.current_slab.bucket_price_to)
            ) {
                this.setState({ slabComplete: true });

                setTimeout(() => {
                    this.setState({ slabComplete: false });
                }, 5000);
            }
        }
    }

    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };


    __addonSum = (item) => {
        let data = item?.selectedaddons;
        let sum = 0;
        data?.forEach((i) => {
            sum += parseFloat(i.price * i.quantity + item?.selling_price * i.quantity)
        });
        let subtotal = sum;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price * item?.quantity;
        return formatPrice(total);
    }


    __comboSum = (item) => {
        let data = item?.selectedcombos;
        let sum = 0;
        data?.forEach((i) => {
            sum += parseFloat(i.price)
        });
        let subtotal = sum + item?.selling_price;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price * item?.quantity;
        return formatPrice(total);
    }

    superSum = (item) => {

        if (item.selectedcombos?.length > 0) {
            return parseFloat(this.__comboSum(item));
        }

        if (item.selectedaddons?.length > 0) {
            return parseFloat(this.__addonSum(item));
        }
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = item?.selling_price - parseFloat(item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = item.selling_price;
        }
        return sell_price * item.quantity;
    }
    addProductQuantity = (product, addon_id) => {
        const { cartProducts, updateCart, storeInfo } = this.props;
        let productAlreadyInCart = false;
        // this.props.calculateStoreChargeTax(cartProducts);
        const index = cartProducts.findIndex(
            (p) =>
                p.id === product.id &&
                JSON.stringify(p) === JSON.stringify(product)
        );

        if (index >= 0) {
            cartProducts.forEach((cp) => {
                if (cp.id === product.id) {
                    if (cp?.selectedaddons?.length > 0 &&
                        JSON.stringify(cp.selectedaddons) ===
                        JSON.stringify(product.selectedaddons)
                    ) {
                        cp.selectedaddons.forEach((addn) => {
                            if (addn.addon_id === addon_id) {
                                addn.quantity += 1;
                            }
                        })
                        // if (cp.quantity < product.stock) {
                        // }
                        productAlreadyInCart = true;
                    } else if (cp?.selectedcombos?.length > 0 && JSON.stringify(cp.selectedcombos) === JSON.stringify(product.selectedcombos)) {
                        // if (cp.quantity < product.stock) {
                        cp.quantity += 1;
                        // }
                        productAlreadyInCart = true;
                    } else {
                        cp.quantity += 1;
                        productAlreadyInCart = true;
                    }
                }
            });
        }
        if (!productAlreadyInCart) {
            cartProducts.push(product);
        }

        // ////// console.log(storeInfo);
        const isFreeItemActive = storeInfo?.is_free_item === 1;
        const newTotalPrice = cartProducts.reduce((total, item) => total + this.superSum(item), 0);

        const newTotalPriceWithoutCombo = cartProducts.reduce((total, item) => {
            if (item?.is_combo == 0 && item?.combo_categories?.length == 0) {
                return total + this.superSum(item);
            }
            return total;
        }, 0);
        const hasReachedMinSubtotal = newTotalPriceWithoutCombo >= parseFloat(storeInfo?.free_item_min_subtotal);

        if (storeInfo?.free_item_min_subtotal !== 0 && isFreeItemActive && hasReachedMinSubtotal) {
            // Add free product to cart if not already added
            ////// console.log('free item activated1');
            ////// console.log(storeInfo);
            if (cartProducts.find(cp => cp.id === storeInfo?.free_item_id) === undefined) {
                if (storeInfo && storeInfo.free_product) {
                    storeInfo.free_product.quantity = 1;
                    cartProducts.push(storeInfo.free_product);
                    ////// console.log('free item activated -inside condition');
                }

                this.setState({ confetti: true, free_item_added: true });
                setTimeout(() => {
                    this.setState({ confetti: false, free_item_added: false });
                }, 3000);
            }
        }
        // alert(newTotalPrice)

        updateCart(cartProducts);
    };

    removeProductQuantity = (product, type, addon_id) => {
        const { user, cartProducts, updateCart, cartTotal, storeInfo } = this.props;
        // this.props.calculateStoreChargeTax(cartProducts);

        const index = cartProducts.findIndex(
            (p) =>
                p.id === product.id &&
                JSON.stringify(p) === JSON.stringify(product)
        );
        //if product is in the cart then index will be greater than 0
        if (index >= 0) {
            cartProducts.forEach((cp) => {
                if (cp.id === product.id) {
                    if (JSON.stringify(cp) === JSON.stringify(product)) {
                        if (type === 'addon') {
                            const addonIndex = cp.selectedaddons.findIndex((a) => a.addon_id === addon_id);
                            cp.selectedaddons.forEach((addn) => {
                                if (addn.addon_id === addon_id) {
                                    if (cp.selectedaddons.length === 1 && addn.quantity === 1) {
                                        cartProducts.splice(index, 1);
                                    } else if (addn.quantity === 1) {
                                        cp.selectedaddons.splice(addonIndex, 1);
                                    } else {
                                        addn.quantity -= 1;
                                    }
                                }
                            });
                        } else {
                            if (cp.quantity === 1) {
                                cartProducts.splice(index, 1);
                            } else {
                                cp.quantity -= 1;
                            }
                        }
                    }
                }
            });

            if (storeInfo?.is_free_item == 1) {
                // const newTotalPrice = cartProducts.reduce((total, item) => total + this.superSum(item), 0);

                const newTotalPriceWithoutCombo = cartProducts.reduce((total, item) => {
                    if (item?.is_combo == 0 && item?.combo_categories?.length == 0) {
                        return total + this.superSum(item);
                    }
                    return total;
                }, 0);

                if (newTotalPriceWithoutCombo < parseFloat(storeInfo?.free_item_min_subtotal)) {

                    const freeItemIndex = cartProducts.findIndex((cp) => cp.id === storeInfo?.free_item_id);
                    if (freeItemIndex >= 0) {
                        cartProducts.splice(freeItemIndex, 1);
                    }

                    const remainingAmount = parseFloat(storeInfo?.free_item_min_subtotal) - newTotalPriceWithoutCombo;
                    this.setState({
                        remainingAmount,
                        remainingPopup: true
                    })

                    // Update the cart after removing the free item
                }
            }

            updateCart(cartProducts);

            // if (this.props.coupon && this.props.min_sub_total < cartTotal) {
            // ////// console.log(this.props.coupon);
            // this.props.removeCoupon();

            // }
        }
    };     
    removeProduct = (product) => {
        const { cartProducts, updateCart } = this.props;
        const index = cartProducts.findIndex((cp) => cp.id === product.id);

        // ////// console.log(index);
        cartProducts.splice(index, 1);
        // ////// console.log(cartProducts);
        updateCart(cartProducts);
        this.forceStateUpdate();
        // this.props.removeCoupon();

        // this.checkForItemsAvailability();
    };

    __doesRestaurantOperatesAtThisLocation = () => {
        //send user lat long to helper, check with the current restaurant lat long and setstate accordingly
        this.setState({ loading: true });
        const { cartProducts, user } = this.props;
        this.props
            .calcualteStoreMaxDistance(
                user.success && user.data.id,
                cartProducts,
                JSON.parse(localStorage.getItem("userSetAddress"))
            )
            .then((response) => {
                if (response && response.payload) {
                    this.setState({
                        max_distance: response.payload.distance,
                        store: response.payload.store_id,
                    });
                    
                    localStorage.setItem("max_distance", response.payload.distance);

                    //if Google Distance Matrix API is enabled
                    let self = this;
                    let distance = 0;
                    if (localStorage.getItem("userSetAddress") !== null) {
                        distance = calculateDistanceGoogle(
                            response.payload.store_id.longitude,
                            response.payload.store_id.latitude,
                            JSON.parse(localStorage.getItem("userSetAddress"))
                                .lng,
                            JSON.parse(localStorage.getItem("userSetAddress"))
                                .lat,
                            this.props.google,
                            function (distance) {
                                // ////// console.log(distance);
                                self.setState(
                                    { distance: distance },
                                    self.__processRestaurantOperationalState(
                                        response.payload.store_id.id,
                                        JSON.parse(
                                            localStorage.getItem(
                                                "userSetAddress"
                                            )
                                        ).lat,
                                        JSON.parse(
                                            localStorage.getItem(
                                                "userSetAddress"
                                            )
                                        ).lng
                                    )
                                );
                            }
                        );
                    } else {
                        this.setState({
                            is_operational: true,
                            loading: false,
                        });
                    }
                }
            });
    };

    __processRestaurantOperationalState = (id, lat, lng) => {
        this.props
            .getStoreInfoAndOperationalStatus(id, lat, lng)
            .then((response) => {
                if (response) {
                    if (response.payload.is_operational) {
                        this.setState({
                            is_operational: true,
                            is_operational_loading: false,
                        });
                    } else {
                        this.setState({
                            is_operational: false,
                            store_close: false,
                            is_operational_loading: false,
                        });
                    }
                }
            });
    };

    noPrescription = (e) => {
        e.preventDefault();
        this.executeScroll()
        this.setState({ noPres: true, no_images: true });
        setTimeout(() => {
            this.setState({ no_images: false });
        }, 1000);
    };

    getImage = (image) => {
        this.setState({ imageAdd: true })
        const { user } = this.props;
        let files = this.state.files;
        let images = this.state.images;
        let imageFile = URL.createObjectURL(image);
        files.push(imageFile);
        images.push(image);
        this.setState({ files: files, images: images });
        let formData = new FormData();
        formData.append("token", user.data.auth_token);
        formData.append("image", image);
        this.props.addPrescription(formData).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ imageAdd: false });
            }
        });
        this.setState({ noPres: false });
    };
    handlePopup = (e, id) => {
        if (id) {
            this.setState({ image_id: id });
        }
        this.setState({ open: !this.state.open });
    };
    removePrescription = () => {
        const { user } = this.props;
        this.props
            .prescriptionDelete(user.data.auth_token, this.state.image_id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ open: !this.state.open });
                }
            });
    };

    needLocation = (e) => {
        e.preventDefault();
        const { user } = this.props;
        // console.log(user,"hhhhhh")

        if(!user || user.length === 0 ){
            this.props.history.push("/login");   

            
        }else{
            localStorage.setItem("LocationFrom", "CART");
            localStorage.setItem("LocationData", "UPDATE");
            this.props.history.push("/my-address");
        }
       
    }

    handleInstruction = (e) => {

        this.setState({ instructions: e?.target?.value });
        localStorage.setItem("orderComment", e?.target?.value);
    }

    totalSavings = () => {
        const { cartProducts, coupon } = this.props;
        let total = 0;
        let couponDiscount = 0;
        let freeItemAMount = 0;
        let lastPrice = 0;
        let dealOfferDis = 0;
        let dealOffer = 0;
        if (coupon?.finalDiscount > 0) {
            couponDiscount = coupon?.finalDiscount;
        }

        cartProducts?.map((c) => {
            if (c.is_free_item) {
                freeItemAMount += c.selling_price;
            }

            if (c.market_price > 0) {
                lastPrice += (c.market_price - c.selling_price) * c.quantity;
            }

            if (c?.dealofdays?.length > 0) {
                let dis = c.selling_price * c.dealofdays[0].offer / 100;

                dealOfferDis = c.selling_price - dis;

                dealOffer = c.selling_price - dealOfferDis;
            }
        })
        let delivery_charges_for_subscribers = localStorage.getItem("delivery_charges_for_subscribers");
        let delivery_charges_distance_free_delivery = localStorage.getItem("delivery_charges_distance_free_delivery")

        total = couponDiscount + freeItemAMount + lastPrice + dealOffer + (delivery_charges_for_subscribers > 0 && parseFloat(delivery_charges_for_subscribers)) + (delivery_charges_distance_free_delivery > 0 && parseFloat(delivery_charges_distance_free_delivery));

        return formatPrice(total);
    };

    render() {

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        const {
            cartProducts,
            user,
            cartTotal,
            charges,
            coupon,
            prescriptionImage, store_info
        } = this.props;
        const { address, store, nearstores, is_deliverable } = this.state;

        return (
            <React.Fragment>
                {this.state.confetti && (
                    <Confetti
                        width={500}
                        confettiSource={{ x: 30, y: 90, w: 500, h: 5000 }}
                        gravity={0.3}
                        friction={0.99}
                        numberOfPieces={500}
                        height={5000}
                    />
                )}
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#0000"}
                        >
                            <rect
                                x="10"
                                y="20"
                                rx="5"
                                ry="5"
                                width="22"
                                height="22"
                            />
                            <rect
                                x="10"
                                y="67"
                                rx="6"
                                ry="6"
                                width="91"
                                height="24"
                            />
                            <rect
                                x="10"
                                y="109"
                                rx="15"
                                ry="15"
                                width="258"
                                height="65"
                            />
                            <rect
                                x="0"
                                y="190"
                                rx="0"
                                ry="0"
                                width="300"
                                height="40"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {/* {cartProducts ? //for test */}
                        {/* {////// console.log(window)} */}
                        {cartProducts && cartProducts.length > 0 ? (
                            <div className="mobile-cart-bg-img">
                                <MobileBackButtonWithCenterTitle
                                    wayToHome={true}
                                    title={"Cart"}
                                />
                                <div
                                    className=""
                                    style={{ paddingBottom: "5vw" }}
                                >
                                    <div
                                        className=" pt-2 px-4 text-black"
                                        style={{
                                            fontSize: "2em",
                                            fontWeight: "700",
                                        }}
                                    >
                                        {cartProducts[0]?.store?.name}
                                    </div>
                                    <div
                                        className="text-black px-4"
                                        style={{ fontSize: "0.9em" }}
                                    >
                                        {cartProducts[0]?.store?.address
                                            ? cartProducts[0]?.store?.address +
                                            ", "
                                            : ""}{" "}
                                        {cartProducts[0]?.store
                                            ?.delivery_radius + " km"}
                                    </div>
                                    <Savings
                                            cartProducts={cartProducts}
                                            coupon={coupon}
                                            user={user}
                                        />
                                    <Items
                                        addProductQuantity={(e, addon_id) =>
                                            this.addProductQuantity(e, addon_id)
                                        }
                                        removeProductQuantity={(e, type, addon_id) =>
                                            this.removeProductQuantity(e, type, addon_id)
                                        }

                                    />
                                    <div className="px-3 pt-3">
                                        <div className="mobile-cart-tip-card py-3 mt-4">
                                            <div className="mobile-cart-tip-cmnt px-3 " style={{ fontSize: '14px' }}>
                                                Write your comments/suggestions
                                                to the store
                                            </div>
                                            <div className=" px-3 mt-3">
                                                <input
                                                    value={
                                                        this.state.instructions
                                                    }
                                                    type="text"
                                                    className="location-input w-100 p-2 mt-2"
                                                    placeholder="Type here"
                                                    onChange={(e) =>
                                                        this.handleInstruction(
                                                            e
                                                        )
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {cartProducts && cartProducts.find((id) => id?.store?.store_category_id === 2) !== undefined && (
                                        <div
                                            className="d-flex flex-column text-center px-3 mt-3"
                                            ref={this.myRef}
                                        >
                                            {this.state.noPres ? (
                                                <Shake when={this.state.no_images}   >
                                                    <div style={{ color: "red" }}    >
                                                        <BiErrorCircle size={25} set="bulk" />{" "}
                                                        Some of the
                                                        items in the
                                                        cart require
                                                        prescription
                                                    </div>
                                                </Shake>
                                            ) : (
                                                <div>
                                                    Upload your
                                                    prescription to
                                                    complete your order
                                                </div>
                                            )}
                                            <div className="mt-4">
                                                <div
                                                    className="main-menu hidden-scroll-bar gap-3"
                                                    style={{
                                                        gridTemplateColumns: `repeat(${prescriptionImage &&
                                                            prescriptionImage.image &&
                                                            prescriptionImage
                                                                .image
                                                                .length +
                                                            1
                                                            },2fr)`,
                                                    }}
                                                >
                                                    {prescriptionImage &&
                                                        prescriptionImage.image &&
                                                        prescriptionImage
                                                            .image
                                                            .length >
                                                        0 &&
                                                        prescriptionImage.image.map(
                                                            (
                                                                item,
                                                                index
                                                            ) => (
                                                                <div className="d-flex flex-column align-items-center">
                                                                    <ImageList
                                                                        item={
                                                                            item
                                                                        }
                                                                    />
                                                                    <div
                                                                        className="py-2 cart-image-delete px-2"
                                                                        style={{
                                                                            width: "26vw",
                                                                        }}
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.handlePopup(
                                                                                e,
                                                                                item.id
                                                                            )
                                                                        }
                                                                    >
                                                                        remove{" "}
                                                                        <span className="text-end">
                                                                            {" "}
                                                                            <AiFillDelete />{" "}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    {this.state
                                                        .imageAdd && (
                                                            <div
                                                                className="mt-4"
                                                                style={{
                                                                    width: "26vw",
                                                                }}
                                                            >
                                                                <Lottie
                                                                    options={
                                                                        defaultOptions
                                                                    }
                                                                    height={
                                                                        100
                                                                    }
                                                                    width={
                                                                        100
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div
                                                className="py-2 w-100 mt-4 position-relative"
                                                style={{
                                                    border: "2px dashed #DB9F78",
                                                    borderRadius:
                                                        "12px",
                                                    color: "#DB9F78",
                                                }}
                                            >
                                                <ImUpload2 size={20} />
                                                <label
                                                    for="image"
                                                    className="ps-1 user-select-none"
                                                    style={{
                                                        color: "#DB9F78",
                                                    }}
                                                >
                                                    Upload prescription
                                                    <Ink
                                                        duration={500}
                                                        style={{
                                                            color: "#aaa",
                                                        }}
                                                    />
                                                </label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{
                                                        display: "none",
                                                    }}
                                                    id="image"
                                                    onChange={(
                                                        event
                                                    ) => {
                                                        let file =
                                                            event.target
                                                                .files[0];
                                                        this.getImage(
                                                            file
                                                        );
                                                        // ////// console.log(event.target, '1')
                                                        event.target.value =
                                                            null;
                                                        // ////// console.log(event.target, '2')
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/* {user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0 ? (
                                        <div className="bg-success w-full px-3 py-2 d-flex align-items-center justify-content-center w-full my-2 fs-6 text-white gap-2 mx-3 rounded-4">
                                            <span>
                                                <BsScooter size={30} />
                                            </span>
                                            <div className="flex flex-column">
                                                <div>

                                                    Woah!! You've achieved Free Delivery!!
                                                </div>
                                                <div className="text-white fs-7">
                                                    You've subscribed to our "{user?.data?.activePlan?.subscription?.plan_name}" Plan
                                                </div>
                                            </div>

                                        </div>
                                    ) : (
                                        <>
                                            {cartProducts[0]?.store?.is_freedelivery == 1 &&
                                                <div className="bg-success w-full px-3 py-2 d-flex align-items-center justify-content-center w-full my-2 fs-6 text-white gap-2 mx-3 rounded-4">
                                                    <span><BsScooter size={30} />
                                                    </span> Free Delivery Above Rs.{cartProducts[0]?.store?.free_delivery_on_above_subtotal} Upto {cartProducts[0]?.store?.free_base_delivery_distance_within} Kms
                                                </div>
                                            }
                                        </>
                                    )} */}
                                        {/* {this.totalSavings() > 0 ? (
                                            <div className="px-3 d-flex justify-content-between mobile-cart-savings-bg mt-4">
                                                <div className="mobile-cart-savings">
                                                    {" "}
                                                    Your total savings{" "}
                                                </div>
                                                <div className="mobile-cart-savings">
                                                    {" "}
                                                    Rs{" "}
                                                    {this.totalSavings()}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )} */}


                                    <Meals
                                        addProductQuantity={(e) =>
                                            this.addProductQuantity(e)
                                        }
                                        removeProductQuantity={(e) =>
                                            this.removeProductQuantity(e)
                                        }
                                    />
                                    <div className="py-3 text-center">
                                        <Link
                                            to={
                                                "single-store/" +
                                                cartProducts[0]?.store.id
                                            }
                                            style={{
                                                color: "#FD8814",
                                                fontSize: "1.2em",
                                            }}
                                        >
                                            Add More Items +
                                        </Link>
                                    </div>
                                    {user.success && (
                                        <CouponBlock
                                            applyCoupon={this.props.applyCoupon}
                                            coupon={this.props.coupon}
                                            coupon_error={
                                                this.state.coupon_error
                                            }
                                            history={this.props.history}
                                            store_id={cartProducts[0]?.store.id}
                                        />
                                    )}
                                    <div
                                        style={
                                            address?.house == "" ||
                                                address?.house == null
                                                ? { paddingBottom: "40vw" }
                                                : { paddingBottom: "60vw" }
                                        }
                                    >
                                        <Tip
                                            addTip={(e) => this.addTip(e)}
                                            tip={this.state.tip}
                                        />
                                        <div className="mobile-cart-cnltn-card mt-3 p-3">
                                            <h1 className="mobile-cart-cn-hd">
                                                Cancellation Policy
                                            </h1>
                                            <span
                                                className="text-muted"
                                                style={{ fontSize: ".9em" }}
                                            >
                                                100% cancellation fee will be
                                                applicable if you decide to
                                                cancel the order anytime after
                                                order placement
                                            </span>
                                        </div>
                                        {/* detail start */}

                                        {/* <div className={this.state.header ? "w-100 px-4 mb-4 mt-2 header-body-active " : "w-100 px-4 header-body"} onScroll={this.handleScroll} >
                                        <div className="d-flex justify-content-between align-items-center w-100 py-2 px-3 " style={{ backgroundColor: '#fff', borderRadius: '10px' }}>
                                            asdads
                                        </div>
                                    </div> */}
                                        {address?.house == "" ||
                                            address?.house == null ? (
                                            <div
                                                className="w-100 px-3 pb-4 py-2 header-body bottom-0 "
                                                style={{
                                                    boxShadow:
                                                        "0px -5px 10px 5px #00000015",
                                                    borderRadius:
                                                        "14px 14px 0px 0px",
                                                    backgroundColor: "#fff",
                                                    zIndex: "9999",
                                                }}
                                            >
                                                <div className="d-flex flex-column justify-content-between align-items-center w-100 pb-2">
                                                    <div
                                                        className="px-2 w-100"
                                                        style={{
                                                            fontSize: "1.2em",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        <TiLocationArrow
                                                            color="#ff9012"
                                                            size={40}
                                                        />{" "}
                                                        <span className="pt-2">
                                                            You are in a new
                                                            Location!
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="text-center w-100 py-3 px-3 cart-no-address mt-2"
                                                        style={{
                                                            boxShadow:
                                                                "0px -5px 10px 5px #00000015",
                                                            fontSize: "1.2em",
                                                        }}
                                                        onClick={(e) =>
                                                            this.needLocation(e)
                                                        }
                                                    >
                                                       Confirm your location
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-3 mobile-cart-cnltn-card">
                                                <div className="py-2 mobile-cart-cnltn-card">
                                                    <div className="mobile-cart-yr-dtls px-3 ">
                                                        Your Details
                                                    </div>
                                                    {address ? (
                                                        <div className="d-flex flex-row justify-content-between px-3 align-items-center mt-1 ">
                                                            <div className="d-flex flex-column">
                                                                <div
                                                                    className="text-muted"
                                                                    style={{
                                                                        fontSize:
                                                                            ".9em",
                                                                    }}
                                                                >
                                                                    {address?.house &&
                                                                        address?.house !==
                                                                        address.address
                                                                        ? address.house +
                                                                        ","
                                                                        : ""}{" "}
                                                                    {
                                                                        address.address
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="ps-2">
                                                                <div
                                                                    className="mobile-cart-chng"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.changeAddress(
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    change{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex flex-row justify-content-between align-items-center px-3 mt-1">
                                                            <div className="d-flex flex-column">
                                                                <div
                                                                    className="text-muted"
                                                                    style={{
                                                                        fontSize:
                                                                            ".9em",
                                                                    }}
                                                                >
                                                                    No Address
                                                                </div>
                                                            </div>

                                                            <div className="ps-2">
                                                                <div
                                                                    className="mobile-cart-chng"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.changeAddress(
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    Add{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {/* detail end */}

                                        {/* cancellation plcy start */}

                                        {/* {////// console.log(cartProducts.find((id) => id.item_category_id === 2), '454545')} */}
                                        {/* cancellation plcy end */}

                                    </div>
                                    <Modal
                                        isOpen={this.state.open}
                                        // onAfterOpen={afterOpenModal}
                                        ariaHideApp={false}
                                        onRequestClose={() =>
                                            this.handlePopup()
                                        }
                                        style={customStyles}
                                        contentLabel="Example Modal"
                                    >
                                        <div
                                            className="container"
                                            style={{
                                                borderRadius: "5px",
                                                height: "150px",
                                                zIndex: "999999999999999999999",
                                            }}
                                        >
                                            <React.Fragment>
                                                <div className="d-flex flex-column justify-content-between">
                                                    <div className="px-10 col-12 pb-3 d-flex justify-content-between align-items-center">
                                                        <h1 className="mt-2 mb-0 font-weight-black h4">
                                                            Do you really want
                                                            to delete this
                                                            image?
                                                        </h1>
                                                    </div>
                                                    {/* <div className="px-10 mb-20">
                                                    Your cart contains items from other store. Do you want to
                                                    discard the selection and add items from this store?
                                                </div> */}
                                                    <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                                                        <div className="text-center mr-4 w-100">
                                                            <button
                                                                className="delete-button-meals p-2 px-2 w-100 position-relative"
                                                                onClick={
                                                                    this
                                                                        .handlePopup
                                                                }
                                                                style={{
                                                                    borderColor:
                                                                        "#fd8814",
                                                                    color: "#fd8814",
                                                                    // border: '1px solid #fd8814',
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                NO
                                                                <Ink
                                                                    duration={
                                                                        500
                                                                    }
                                                                    style={{
                                                                        color: "#000",
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>

                                                        <div className="text-center w-100">
                                                            <button
                                                                className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                                                onClick={this.removePrescription}
                                                                style={{
                                                                    width: "100%",
                                                                    backgroundColor:
                                                                        "#ce0060",
                                                                    borderColor:
                                                                        "#ce0060",
                                                                }}
                                                            >
                                                                YES{" "}
                                                                <Ink
                                                                    duration={
                                                                        500
                                                                    }
                                                                    style={{
                                                                        color: "#000",
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    </Modal>
                                    <div
                                        className="position-fixed bottom-0 w-100"
                                        style={
                                            address?.house == "" ||
                                                address?.house == null
                                                ? { display: "none", zIndex: '100' }
                                                : {
                                                    borderRadius:
                                                        "30px 30px 0px 0px",
                                                    boxShadow:
                                                        "0px 0px 10px 0px #dedede", zIndex: '100'
                                                }
                                        }
                                    >
                                        {cartProducts && cartProducts?.length > 0 && nearstores &&
                                            nearstores.length > 0 &&
                                            nearstores.find(
                                                (cp) =>
                                                    cp ===
                                                    cartProducts[0]?.store?.id
                                            )&& this.state.is_deliverable ? (
                                            <Bill
                                                total={cartTotal.totalPrice}
                                                discount={
                                                    cartTotal.totalDiscount
                                                }
                                                distance={this.state.distance}
                                                tip={this.calculatetip()}
                                                cartProducts={cartProducts}
                                                charges={charges}
                                                coupon={coupon}
                                                noPrescription={(e) =>
                                                    this.noPrescription(e)
                                                }
                                                max_distance={
                                                    this.state.max_distance
                                                }
                                                user={user}
                                                current_slab={
                                                    this.props.current_slab
                                                }
                                                totalSavings={this.totalSavings}
                                                store={this.state.store}
                                            />
                                        ) : (
                                            <div className="position-fixed bottom-0 w-100">
                                                <div
                                                    className="w-100 px-3 pb-3 py-2 header-body bottom-0 "
                                                    style={
                                                        address?.house == "" ||
                                                            address?.house == null
                                                            ? {
                                                                display:
                                                                    "none",
                                                            }
                                                            : {
                                                                paddingBottom:
                                                                    "60vw",
                                                                boxShadow:
                                                                    "0px -5px 10px 5px #00000015",
                                                                borderRadius:
                                                                    "14px 14px 0px 0px",
                                                                backgroundColor:
                                                                    "#fff",
                                                                zIndex: "9999",
                                                            }
                                                    }
                                                >
                                                    <div className="d-flex flex-column justify-content-between align-items-center w-100">
                                                        <div className="d-flex flex-row align-items-center px-2 w-100">
                                                            <TbLocationOff
                                                                color="#ff9012"
                                                                size={55}
                                                            />
                                                            <span
                                                                className="ps-3 pt-2"
                                                                style={{
                                                                    fontSize:
                                                                        "1.2em",
                                                                    fontWeight:
                                                                        "650",
                                                                }}
                                                            >
                                                                {" "}
                                                             We are not
                                                                currently
                                                                available at
                                                                this location.
                                                                Please try
                                                                changing your
                                                                location
                                                            </span>
                                                        </div>
                                                        <Link
                                                            to="/my-address"
                                                            className="w-100"
                                                        >
                                                            <div
                                                                className="text-center w-100 py-3 px-3 cart-no-address mt-3"
                                                                style={{
                                                                    boxShadow:
                                                                        "0px -5px 10px 5px #00000015",
                                                                    fontSize:
                                                                        "1.2em",
                                                                }}
                                                            >
                                                                Change Location
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="mobile-cart-bg-img">
                                <MobileBackButtonWithCenterTitle
                                    wayToHome={true}
                                    title={"Cart"}
                                />
                                <div
                                    className="d-flex flex-column align-items-center"
                                    style={{ paddingTop: "22vw" }}
                                >
                                    <div className="pt-3 d-flex flex-column align-items-center">
                                        <div className="mt-3 d-flex flex-column align-items-center">
                                            <img
                                                src={image}
                                                alt="no items"
                                                style={{
                                                    objectFit: "cover",
                                                    width: "70%",
                                                }}
                                            />
                                        </div>
                                        <span className="mt-4 fs-7">
                                            Sorry...No items Found !!!
                                        </span>
                                    </div>
                                    <span
                                        className="fs-4"
                                        style={{
                                            fontFamily: "fantasy",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Cart is Empty
                                    </span>
                                    <div
                                        className="position-fixed py-2 w-100 px-3"
                                        style={{
                                            bottom: "0",
                                            backgroundColor: "#fff",
                                        }}
                                    >
                                        <Link to={"/home"}>
                                            <div
                                                className="w-100 mobile-sign-up-adress-btn-3 text-center"
                                                style={{
                                                    fontSize: "1.1em",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                Go Back to Home
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <Footer active_favourite={true} /> */}
                        {/* <FloatCart float={this.state.floatCart} /> */}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
    cartTotal: state.cart.total,
    deliveryCharges: state.cart.delivery_charges,
    user: state.user.user,
    store_info: state.cart.store_info,
    storeInfo: state.cart.store_info,
    current_slab: state.cart.current_slab,
    coupon: state.mobileCoupon.appliedCoupon,
    coupons: state.mobileCoupon.coupons,
    charges: state.cart.charges,
    next_slab: state.cart.next_slab,
    slabs: state.cart.slabs,
    prescriptionImage: state.cart.prescriptionImage,
});

export default connect(mapStateToProps, {
    getCartProducts,
    updateCart,
    applyCoupon,
    calcualteStoreMaxDistance,
    calculateStoreChargeTax,
    getStoreInfoAndOperationalStatus,
    addPrescription,
    prescriptionDelete,
    getSettings,
    updateStore
})(Cart);

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
